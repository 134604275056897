import { CSSObject } from '@emotion/react';
import { luminaColorPallete } from 'styles/mui/components/Color';

export const luminaButton = {
    contained: {
        backgroundColor: `${luminaColorPallete.sky[600]} !important`,
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: `${luminaColorPallete.sky[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            backgroundColor: `${luminaColorPallete.sky[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    containedFire: {
        backgroundColor: `${luminaColorPallete.fire[300]} !important`,
        color: 'white !important',
        textTransform: 'none',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: `${luminaColorPallete.fire[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            backgroundColor: `${luminaColorPallete.fire[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    outlined: {
        textTransform: 'none',
        border: `1px solid ${luminaColorPallete.greyscale[100]} !important`,
        color: `${luminaColorPallete.sky[600]} !important`,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            borderColor: `${luminaColorPallete.sky[600]} !important`,
            backgroundColor: `${luminaColorPallete.sky[50]} !important`,
            color: `${luminaColorPallete.sky[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    outlinedPrimary: {
        textTransform: 'none',
        border: `1px solid ${luminaColorPallete.greyscale[100]} !important`,
        color: `${luminaColorPallete.sky[600]} !important`,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            borderColor: `${luminaColorPallete.sky[600]} !important`,
            backgroundColor: `${luminaColorPallete.sky[600]} !important`,
            color: `white !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    outlinedFire: {
        textTransform: 'none',
        border: `1px solid ${luminaColorPallete.greyscale[100]} !important`,
        color: `${luminaColorPallete.fire[600]} !important`,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            borderColor: `${luminaColorPallete.fire[600]} !important`,
            backgroundColor: `${luminaColorPallete.fire[50]} !important`,
            color: `${luminaColorPallete.fire[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            color: `${luminaColorPallete.fire[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    outlinedSky: {
        textTransform: 'none',
        border: `1px solid ${luminaColorPallete.greyscale[100]} !important`,
        borderColor: `${luminaColorPallete.sky[600]} !important`,
        backgroundColor: `${luminaColorPallete.sky[50]} !important`,
        color: `${luminaColorPallete.sky[500]} !important`,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    text: {
        textTransform: 'none',
        color: `${luminaColorPallete.sky[600]} !important`,
        '&:hover': {
            color: `${luminaColorPallete.sky[400]} !important`
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`
        },
        '&:disabled': {
            color: `${luminaColorPallete.greyscale[300]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    textGray: {
        textTransform: 'none',
        color: `${luminaColorPallete.greyscale[600]} !important`,
        '&:hover': {
            color: `${luminaColorPallete.sky[400]} !important`
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`
        },
        '&:disabled': {
            color: `${luminaColorPallete.greyscale[300]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject
};

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlinedFire: true;
        containedFire: true;
    }

    interface ButtonClasses {
        /** Styles applied to the root element. */
        root: string;
        /** Styles applied to the root element if `variant="text"`. */
        text: string;
        /** Styles applied to the root element if `variant="text"` and `color="inherit"`. */
        textInherit: string;
        /** Styles applied to the root element if `variant="text"` and `color="primary"`. */
        textPrimary: string;
        /** Styles applied to the root element if `variant="text"` and `color="secondary"`. */
        textSecondary: string;
        /** Styles applied to the root element if `variant="outlined"`. */
        outlined: string;
        /** Styles applied to the root element if `variant="outlined"` and `color="inherit"`. */
        outlinedInherit: string;
        /** Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
        outlinedPrimary: string;
        /** Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
        outlinedSecondary: string;
        /** Styles applied to the root element if `variant="contained"`. */
        contained: string;
        /** Styles applied to the root element if `variant="contained"` and `color="inherit"`. */
        containedInherit: string;
        /** Styles applied to the root element if `variant="contained"` and `color="primary"`. */
        containedPrimary: string;
        /** Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
        containedSecondary: string;
        /** Styles applied to the root element if `disableElevation={true}`. */
        disableElevation: string;
        /** State class applied to the ButtonBase root element if the button is keyboard focused. */
        focusVisible: string;
        /** State class applied to the root element if `disabled={true}`. */
        disabled: string;
        /** Styles applied to the root element if `color="inherit"`. */
        colorInherit: string;
        /** Styles applied to the root element if `size="small"` and `variant="text"`. */
        textSizeSmall: string;
        /** Styles applied to the root element if `size="medium"` and `variant="text"`. */
        textSizeMedium: string;
        /** Styles applied to the root element if `size="large"` and `variant="text"`. */
        textSizeLarge: string;
        /** Styles applied to the root element if `size="small"` and `variant="outlined"`. */
        outlinedSizeSmall: string;
        /** Styles applied to the root element if `size="medium"` and `variant="outlined"`. */
        outlinedSizeMedium: string;
        /** Styles applied to the root element if `size="large"` and `variant="outlined"`. */
        outlinedSizeLarge: string;
        /** Styles applied to the root element if `size="small"` and `variant="contained"`. */
        containedSizeSmall: string;
        /** Styles applied to the root element if `size="medium"` and `variant="contained"`. */
        containedSizeMedium: string;
        /** Styles applied to the root element if `size="large"` and `variant="contained"`. */
        containedSizeLarge: string;
        /** Styles applied to the root element if `size="small"`. */
        sizeSmall: string;
        /** Styles applied to the root element if `size="medium"`. */
        sizeMedium: string;
        /** Styles applied to the root element if `size="large"`. */
        sizeLarge: string;
        /** Styles applied to the root element if `fullWidth={true}`. */
        fullWidth: string;
        /** Styles applied to the startIcon element if supplied. */
        startIcon: string;
        /** Styles applied to the endIcon element if supplied. */
        endIcon: string;
        /** Styles applied to the icon element if supplied and `size="small"`. */
        iconSizeSmall: string;
        /** Styles applied to the icon element if supplied and `size="medium"`. */
        iconSizeMedium: string;
        /** Styles applied to the icon element if supplied and `size="large"`. */
        iconSizeLarge: string;
        containedFire: string;
        outlinedFire: string;
        outlinedSky: string;
    }
}
