import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatestMessage } from 'lib/stanza/inbox/protocol';

export type PendingMessage = LatestMessage & { isPending?: boolean };

export type ChatRoomData = {
    latest_message: LatestMessage;
    pending_messages?: PendingMessage[];
};

export type ChatSource = 'job_application' | 'recommended_worker';

interface ChatRoomInputData {
    user_id: string;
    data: ChatRoomData;
}

type ChatRoomState = {
    selectedChat?: string;
    selectedChatSource?: ChatSource;
    search_query?: string;
    page?: number;
    room_data: {
        [user_id: string]: ChatRoomData;
    };
    latest_message_at?: Date;
    first_message_at?: Date;
};

const chatRoom = createSlice({
    name: 'chatRoom',
    initialState: {
        selectedChat: undefined,
        selectedChatSource: undefined,
        search_query: '',
        page: 1,
        room_data: {},
        latest_message_at: undefined,
        first_message_at: undefined
    } as ChatRoomState,
    reducers: {
        setSelectedChatRoom: (state: ChatRoomState, { payload }: PayloadAction<string>) => {
            return { ...state, selectedChat: payload };
        },
        setSelectedChatRoomSource: (
            state: ChatRoomState,
            { payload }: PayloadAction<ChatSource>
        ) => {
            return { ...state, selectedChatSource: payload };
        },
        setSearchQuery: (state: ChatRoomState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                page: 1,
                search_query: payload
            };
        },
        setChatRoomData: (state: ChatRoomState, { payload }: PayloadAction<ChatRoomInputData>) => {
            if (Object.keys(state.room_data).indexOf(payload.user_id) >= 0) {
                const currentData = state.room_data[payload.user_id];

                return {
                    ...state,
                    room_data: {
                        ...state.room_data,
                        [payload.user_id]: {
                            ...currentData,
                            ...payload.data,
                            pending_messages:
                                (currentData.pending_messages as PendingMessage[])?.length > 0
                                    ? [
                                          ...(currentData.pending_messages as PendingMessage[]),
                                          ...(payload.data.pending_messages as PendingMessage[])
                                      ]
                                    : payload.data.pending_messages ?? []
                        }
                    }
                };
            }

            return { ...state, room_data: { ...state.room_data, [payload.user_id]: payload.data } };
        },
        resetPendingMessages: (state: ChatRoomState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                room_data: {
                    ...state.room_data,
                    [payload]: { ...state.room_data[payload], pending_messages: [] }
                }
            };
        },
        removeMessageFromPending: (
            state: ChatRoomState,
            { payload }: PayloadAction<{ user_id: string; messageId: string }>
        ) => {
            return {
                ...state,
                room_data: {
                    ...state.room_data,
                    [payload.user_id]: {
                        ...state.room_data[payload.user_id],
                        pending_messages: state.room_data[payload.user_id].pending_messages?.filter(
                            (message: PendingMessage) => message.messageId !== payload.messageId
                        )
                    }
                }
            };
        },
        setLatestMessageAt: (state: ChatRoomState, { payload }: PayloadAction<Date>) => {
            return { ...state, latest_message_at: payload };
        },
        setFirstMessageAt: (state: ChatRoomState, { payload }: PayloadAction<Date>) => {
            return { ...state, first_message_at: payload };
        }
    }
});

export const {
    setSelectedChatRoom,
    setSelectedChatRoomSource,
    setSearchQuery,
    setChatRoomData,
    resetPendingMessages,
    removeMessageFromPending,
    setLatestMessageAt,
    setFirstMessageAt
} = chatRoom.actions;

export default chatRoom.reducer;
