import { combineReducers } from '@reduxjs/toolkit';
import employeeManagementReducer from './slices/employeeManagementSlices';
import referralReducer from './slices/referralSlices';

const profileReducer = combineReducers({
    employee: employeeManagementReducer,
    referral: referralReducer
});

export default profileReducer;
