import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from './config';

export const elasticSearchApi = createApi({
    reducerPath: 'elasticSearchApi',
    tagTypes: [],
    baseQuery: fetchBaseQuery({
        baseUrl: config.ES_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Basic ${process.env.NEXT_PUBLIC_ELASTIC_SEARCH_TOKEN}`);
            return headers;
        }
    }),
    endpoints: () => ({})
});
