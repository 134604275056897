import { baseApi } from 'redux/api/baseApi';
import { GigWorkSliceState } from '../slices/gigWorkSlices';

const GIGS_BASE_URL = 'gigs/';

export const gigApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        listGigCategories: builder.query<ResponseData<GigCategory>, void>({
            query: () => ({
                url: `${GIGS_BASE_URL}companies/job-categories/`
            })
        }),
        getGigWork: builder.query<GigWorkFulfillment, string>({
            query: (gigId: string) => ({
                url: `${GIGS_BASE_URL}companies/job-request-fulfillments/${gigId}/`
            }),
            providesTags: [{ type: 'GigWorkFulfillment' }]
        }),
        getGigWorkListFulfillment: builder.query<
            GigWorkFulfillmentResponseData,
            BaseListQueryParams & { id: string }
        >({
            query: ({ id, ...params }: BaseListQueryParams & { id: string }) => ({
                url: `${GIGS_BASE_URL}companies/job-requests/${id}/job-request-fulfillments/`,
                params: {
                    limit: 10,
                    ...params
                }
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(({ id }) => ({ type: 'GigWork', id } as const)),
                          { type: 'GigWorkFulfillment', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'GigWorkFulfillment', id: 'LIST' }]
        }),
        getListGigWorks: builder.query<ListResponseData<GigWorkList>, GigWorkSliceState>({
            query: (params: GigWorkSliceState) => ({
                url: `${GIGS_BASE_URL}companies/job-request-fulfillments/`,
                params: {
                    ...params,
                    start_at: params.start_at !== '' ? `${params.start_at}T00:00:00.000Z` : ''
                }
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(
                              ({ id }) => ({ type: 'GigWorkFulfillment', id } as const)
                          ),
                          { type: 'GigWorkFulfillment', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'GigWorkFulfillment', id: 'LIST' }]
        }),
        getListGigWorksForinfniteScroll: builder.query<
            ListResponseData<GigWorkList>,
            GigWorkSliceState
        >({
            query: (params: GigWorkSliceState) => ({
                url: `${GIGS_BASE_URL}companies/job-request-fulfillments/`,
                params: {
                    ...params,
                    start_at: params.start_at !== '' ? `${params.start_at}T00:00:00.000Z` : ''
                }
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(
                              ({ id }) => ({ type: 'GigWorkFulfillment', id } as const)
                          ),
                          { type: 'GigWorkFulfillment', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'GigWorkFulfillment', id: 'LIST' }]
        }),
        createGigWork: builder.mutation<GigWork, GigWorkInputData>({
            query: (data: GigWorkInputData) => ({
                url: `${GIGS_BASE_URL}companies/job-requests/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'GigWorkFulfillment', id: 'LIST' }, { type: 'LuminaCredit' }]
        }),
        updateGigWorkerStatus: builder.mutation<GigWork, GigWorkerStatusInputData>({
            query: ({ id, ...body }: GigWorkerStatusInputData) => ({
                url: `${GIGS_BASE_URL}companies/job-request-fulfillments/${id}/status/`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['GigWorkFulfillment']
        }),
        getGigWorkStatus: builder.query<ResponseData<GigStatus>, void>({
            query: () => `${GIGS_BASE_URL}companies/job-request-fulfillments/filters/status/`
        }),
        getGigWorkCategory: builder.query<ResponseData<GigWorkCategory>, void>({
            query: () =>
                `${GIGS_BASE_URL}companies/job-request-fulfillments/filters/job-categories/`
        }),
        getGigIncentiveTypes: builder.query<ResponseData<GigIncentiveType>, void>({
            query: () => `${GIGS_BASE_URL}companies/incentive-types/`
        }),
        getGigWorkDistrict: builder.query<ResponseData<GigWorkDistrict>, void>({
            query: () => `${GIGS_BASE_URL}companies/job-request-fulfillments/filters/districts/`
        }),
        submitGigWorkLeadGen: builder.mutation<GigWorkLeadGenInputData, GigWorkLeadGenInputData>({
            query: (data: GigWorkLeadGenInputData) => ({
                url: `${GIGS_BASE_URL}company-candidates/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['FeatureFlag']
        }),
        getLeadGenMetadata: builder.query<GigWorkLeadGenMetadata, void>({
            query: () => ({
                url: `${GIGS_BASE_URL}companies/candidates/metadata/`
            })
        })
    }),
    overrideExisting: false
});

export const {
    useListGigCategoriesQuery,
    useGetListGigWorksQuery,
    useGetListGigWorksForinfniteScrollQuery,
    useLazyGetListGigWorksQuery,
    useLazyGetListGigWorksForinfniteScrollQuery,
    useGetGigWorkQuery,
    useGetGigWorkListFulfillmentQuery,
    useCreateGigWorkMutation,
    useUpdateGigWorkerStatusMutation,
    useGetGigWorkStatusQuery,
    useGetGigWorkCategoryQuery,
    useGetGigIncentiveTypesQuery,
    useGetGigWorkDistrictQuery,
    useSubmitGigWorkLeadGenMutation,
    useGetLeadGenMetadataQuery
} = gigApi;
