export const commonSetState = <T>(
    state: T,
    fieldState: string[],
    fieldName: string,
    payload: string
): T => {
    if (fieldState?.indexOf(payload) === -1) {
        return {
            ...state,
            page: 1,
            [fieldName]: [...fieldState, payload]
        };
    } else {
        return {
            ...state,
            page: 1,
            [fieldName]: fieldState?.filter((data) => data !== payload)
        };
    }
};
