import useCompanyUser from 'authentication/hooks/useCompanyUser';
import { useConsentTnCMutation } from 'authentication/redux/api/authApi';
import { getConsentTnC } from 'authentication/redux/selectors/userSelector';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import { isNotNullAndNotUndefined } from 'core/utils';
import {
    useGetPaymentNoticeQuery,
    useGetSubscriptionDetailQuery
} from 'dashboard/profile/redux/api/profileApi';
import {
    useGetAnnouncementsQuery,
    useGetJobExpiryAnnouncementQuery
} from 'dashboard/redux/api/announcementApi';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type OnboardingContextType = {
    showTooltip: boolean;
    checkOnboardingFlagIsActive: (key: TargetTooltipEnum) => boolean;
    setShowTooltip: (status: boolean) => void;
    step: number;
    setStep: (value: number) => void;
    showTnC: boolean;
    setConsentTnC: (status: boolean) => Promise<void>;
    isLoadingTnC: boolean;
    isLoadingAnnouncement: boolean;
    announcements: Announcement[];
    setShowWhatsNew: (status: boolean) => void;
    showWhatsNew: boolean;
    setShowSubscriptionNotice: (status: boolean) => void;
    showSubscriptionNotice: boolean;
    setShowSubscriptionPaymentNotice: (status: boolean) => void;
    showSubscriptionPaymentNotice: boolean;
    setShowJobExpiry: (status: boolean) => void;
    showJobExpiry: boolean;
    isLoadingJobExpiry: boolean;
    jobExpiry: JobExpiryAnnouncement[];
};

export enum TargetTooltipEnum {
    applicant_onboard = 'applicant_onboard',
    tnc = 'tnc',
    lcs_feature_reminder = 'lcs_feature_reminder',
    subscription_trial_notice = 'subscription_trial_notice'
}

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export enum OnboardingTarget {
    match_quality = 'match_quality',
    expiry = 'expiry'
}

export function OnboardingProvider({ children }: { children: ReactNode }): JSX.Element {
    const track = useLazyAuthTracking('DASHBOARD');
    const [showTooltip, setIsShowTooltip] = useState<boolean>(false);
    const [showTnC, setIsShowTnC] = useState<boolean>(false);
    const [showSubscriptionNotice, setShowSubscriptionNotice] = useState<boolean>(false);
    const [showSubscriptionPaymentNotice, setShowSubscriptionPaymentNotice] =
        useState<boolean>(false);
    const [showWhatsNew, setIsShowWhatsNew] = useState<boolean>(false);
    const [showJobExpiry, setIsShowJobExpiry] = useState<boolean>(false);
    const isConsentTnC = useSelector(getConsentTnC);
    const { isACompanyUser, isAuthenticated } = useCompanyUser();
    const [step, setTooltipStep] = useState(0);
    const [consentTnC, { isLoading: isLoadingTnC }] = useConsentTnCMutation();
    const { data: announcementData, isLoading: isLoadingAnnouncement } = useGetAnnouncementsQuery(
        undefined,
        {
            skip: !isNotNullAndNotUndefined(isAuthenticated) || !isACompanyUser
        }
    );
    const { data: jobExpiryData, isLoading: isLoadingJobExpiry } = useGetJobExpiryAnnouncementQuery(
        undefined,
        {
            skip: !isNotNullAndNotUndefined(isAuthenticated) || !isACompanyUser
        }
    );

    const { data: subscriptionData, isLoading: isLoadingSubscription } =
        useGetSubscriptionDetailQuery(undefined, {
            skip: !isNotNullAndNotUndefined(isAuthenticated) || !isACompanyUser
        });

    const { data: paymentNoticeData, isLoading: isLoadingPaymentNotice } = useGetPaymentNoticeQuery(
        undefined,
        {
            skip: !isNotNullAndNotUndefined(isAuthenticated) || !isACompanyUser
        }
    );

    const checkOnboardingFlagIsActive = (key: TargetTooltipEnum): boolean => {
        if (key === TargetTooltipEnum.tnc) {
            if (isConsentTnC === null) {
                return true;
            }
            return isConsentTnC;
        }

        return window.localStorage.getItem(key) !== null;
    };

    useEffect(() => {
        if (isACompanyUser) {
            if (
                !checkOnboardingFlagIsActive(TargetTooltipEnum.tnc) &&
                !checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard)
            ) {
                setIsShowTnC(true);
            } else if (
                checkOnboardingFlagIsActive(TargetTooltipEnum.tnc) &&
                !checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard)
            ) {
                // have not seen onboarding tooltip but already approve TnC
                window.localStorage.setItem(TargetTooltipEnum.tnc, 'true');
                setStep(1);
            } else if (
                !checkOnboardingFlagIsActive(TargetTooltipEnum.tnc) &&
                checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard)
            ) {
                // old user who already seen onboarding, but not yet seen TnC
                setIsShowTnC(true);
            } else if (
                !isLoadingAnnouncement &&
                announcementData &&
                announcementData?.count_items > 0
            ) {
                // all of the onboarding popup already shown, check what's new
                setIsShowWhatsNew(true);
            } else if (!isLoadingJobExpiry && jobExpiryData && jobExpiryData?.data?.length > 0) {
                // all onboarding popup and announcement already shown, check job expiry popup
                setIsShowJobExpiry(true);
            }
        }
    }, [isConsentTnC, isACompanyUser]);

    useEffect(() => {
        // if user already consented to TnC and completed onboarding, show what's new if any
        if (
            isConsentTnC &&
            checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard) &&
            !isLoadingAnnouncement &&
            announcementData &&
            announcementData?.count_items > 0
        ) {
            setIsShowWhatsNew(true);
        }
    }, [isLoadingAnnouncement, announcementData]);

    useEffect(() => {
        // if user already consented to TnC and completed onboarding, show what's new if any
        if (
            isConsentTnC &&
            checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard) &&
            !isLoadingJobExpiry &&
            jobExpiryData &&
            jobExpiryData?.data?.length > 0 &&
            !isLoadingAnnouncement &&
            announcementData?.count_items === 0
        ) {
            setIsShowJobExpiry(true);
        }
    }, [isLoadingJobExpiry, jobExpiryData, isLoadingAnnouncement, announcementData]);

    useEffect(() => {
        if (
            subscriptionData &&
            subscriptionData.free_trial_end_at &&
            !checkOnboardingFlagIsActive(TargetTooltipEnum.subscription_trial_notice)
        ) {
            setShowSubscriptionNotice(true);
        }
    }, [subscriptionData, isLoadingSubscription]);

    useEffect(() => {
        if (
            paymentNoticeData &&
            isNotNullAndNotUndefined(paymentNoticeData.failed_subscription_renewal)
        )
            setShowSubscriptionPaymentNotice(true);
    }, [paymentNoticeData, isLoadingPaymentNotice]);

    const setShowTooltip = (status: boolean): void => {
        if (!checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard)) {
            if (showTooltip && step > 1 && !status) {
                window.localStorage.setItem(TargetTooltipEnum.applicant_onboard, '1');

                if (!isLoadingAnnouncement && announcementData?.count_items != 0) {
                    // what's new will always shown after onboarding tooltip
                    setIsShowWhatsNew(true);
                } else if (
                    !isLoadingJobExpiry &&
                    jobExpiryData &&
                    jobExpiryData?.data?.length > 0
                ) {
                    setIsShowJobExpiry(true);
                }
            }
        }
        setIsShowTooltip(status);
    };

    const setShowJobExpiry = (status: boolean): void => {
        setIsShowJobExpiry(status);
    };

    const setShowWhatsNew = (status: boolean): void => {
        setIsShowWhatsNew(status);

        if (!status && !isLoadingJobExpiry && jobExpiryData && jobExpiryData?.data?.length > 0) {
            setIsShowJobExpiry(true);
        }
    };

    const setConsentTnC = async (status: boolean): Promise<void> => {
        await consentTnC();

        if (!checkOnboardingFlagIsActive(TargetTooltipEnum.applicant_onboard)) {
            setShowTooltip(true);
            setStep(1);
        } else if (!isLoadingAnnouncement && announcementData?.count_items != 0) {
            // what's new will shown if user already shown tooltip, and recently prompted tnc
            setIsShowWhatsNew(true);
        } else if (!isLoadingJobExpiry && jobExpiryData && jobExpiryData?.data?.length > 0) {
            setIsShowJobExpiry(true);
        }

        track.event('Consent Terms & Conditions');

        setIsShowTnC(status);
    };

    const setStep = (value: number): void => {
        setTooltipStep(value);
    };

    const memoedValue = useMemo(
        () => ({
            showTooltip,
            checkOnboardingFlagIsActive,
            setShowTooltip,
            step,
            setStep,
            setConsentTnC,
            showTnC,
            isLoadingTnC,
            showSubscriptionNotice,
            setShowSubscriptionNotice,
            showSubscriptionPaymentNotice,
            setShowSubscriptionPaymentNotice,
            showWhatsNew,
            setShowWhatsNew,
            showJobExpiry,
            setShowJobExpiry
        }),
        [
            showTooltip,
            step,
            showTnC,
            showSubscriptionNotice,
            showSubscriptionPaymentNotice,
            isLoadingTnC,
            showWhatsNew,
            showJobExpiry
        ]
    );

    return (
        <OnboardingContext.Provider
            value={{
                ...memoedValue,
                isLoadingAnnouncement,
                announcements: announcementData?.data || [],
                isLoadingJobExpiry,
                jobExpiry: jobExpiryData?.data || []
            }}>
            {children}
        </OnboardingContext.Provider>
    );
}

export const useOnboarding = (): OnboardingContextType => {
    return useContext(OnboardingContext);
};

export default OnboardingContext;
