import { baseApi } from 'redux/api/baseApi';

const ANNOUNCEMENT_BASE_URL = 'companies/';

export const announcementApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAnnouncements: builder.query<AnnouncementResponseData, void>({
            query: () => `${ANNOUNCEMENT_BASE_URL}ats-announcements/`
        }),
        saveReadAnnouncement: builder.mutation<void, ViewedAnnouncementData>({
            query: (data: ViewedAnnouncementData) => ({
                url: `${ANNOUNCEMENT_BASE_URL}ats-announcements/viewed/`,
                method: 'POST',
                body: data
            })
        }),
        getATSBanner: builder.query<BannerResponseData, void>({
            query: () => `${ANNOUNCEMENT_BASE_URL}banners/`
        }),
        getJobExpiryAnnouncement: builder.query<ResponseData<JobExpiryAnnouncement>, void>({
            query: () => `jobs/company/job_openings/expiry-pop-up/`,
            providesTags: (result) =>
                result
                    ? // successful query
                      [
                          ...result.data.map(({ id }) => ({ type: 'JobOpening', id } as const)),
                          { type: 'JobOpening', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'JobOpening', id: 'LIST' }]
        }),
        saveJobExpiryAnnouncement: builder.mutation<void, SaveJobExpiryAnnouncementRequestData>({
            query: (data: SaveJobExpiryAnnouncementRequestData) => ({
                url: 'jobs/company/last-shown-job-expiry/',
                method: 'PUT',
                body: data
            })
        })
    }),
    overrideExisting: false
});

export const {
    useGetAnnouncementsQuery,
    useSaveReadAnnouncementMutation,
    useGetATSBannerQuery,
    useGetJobExpiryAnnouncementQuery,
    useSaveJobExpiryAnnouncementMutation
} = announcementApi;
