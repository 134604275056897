import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetState } from 'dashboard/redux/utils';
import mixpanel from 'mixpanel-browser';
import { MobilefilterSliceState } from './mobileFilterApplicantSlices';

export enum ApplicantOrderBy {
    default_sort_by = 'match_quality_desc',
    recently_updated = 'recently_updated',
    newest = 'newest',
    alphabetical_asc = 'alphabetical_asc',
    alphabetical_desc = 'alphabetical_desc',
    match_quality = 'match_quality_desc',
    expiry_date_asc = 'expiry_date_asc'
}

export type FilterSliceState = Omit<Required<ApplicationQueryParams>, 'limit'>;

const filterApplicantSlice = createSlice({
    name: 'filterApplicant',
    initialState: {
        page: 1,
        order_by: ApplicantOrderBy.default_sort_by,
        job_opening_id: [],
        status: [],
        degree: [],
        city: [],
        gender: [],
        age_min: 0,
        age_max: 0,
        search_query: '',
        job_category: [],
        score_assessment: []
    } as FilterSliceState,
    reducers: {
        setJobOpening: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.job_opening_id, 'job_opening_id', payload);
        },
        setJobOpeningFromDetailPage: (
            state: FilterSliceState,
            { payload }: PayloadAction<string>
        ) => {
            return {
                ...state,
                job_opening_id: [payload]
            };
        },
        setJobOpeningFromApplicantAvatars: (
            state: FilterSliceState,
            { payload }: PayloadAction<string>
        ) => {
            return {
                ...state,
                job_opening_id: [payload]
            };
        },
        setStatus: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            if (payload === 'new') {
                return {
                    ...state,
                    status: ['new', 'read']
                };
            }

            return {
                ...state,
                status: [payload]
            };
        },
        setDegree: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.degree, 'degree', payload);
        },
        setGender: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.gender, 'gender', payload);
        },
        setCity: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.city, 'city', payload);
        },
        setJobCategory: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.job_category, 'job_category', payload);
        },
        setScoreAssessment: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.score_assessment, 'score_assessment', payload);
        },
        setSearchQuery: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                page: 1,
                search_query: payload
            };
        },
        setFilterFromMobile: (
            state: FilterSliceState,
            { payload }: PayloadAction<MobilefilterSliceState>
        ) => {
            return {
                ...state,
                ...payload
            };
        },
        changePage: (state: FilterSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        },
        setOrderBy: (state: FilterSliceState, { payload }: PayloadAction<ApplicantOrderBy>) => {
            return {
                ...state,
                order_by: payload,
                page: 1
            };
        },
        resetFilter: () => {
            mixpanel.track('Resets Job Application List Filter', { Tag: 'JOB APPLICATION' });
            return {
                page: 1,
                order_by: ApplicantOrderBy.default_sort_by,
                job_opening_id: [],
                status: [],
                degree: [],
                city: [],
                gender: [],
                age_min: 0,
                age_max: 0,
                search_query: '',
                job_category: [],
                score_assessment: []
            };
        }
    }
});

export const {
    setJobOpening,
    setJobOpeningFromDetailPage,
    setJobOpeningFromApplicantAvatars,
    setCity,
    setJobCategory,
    setScoreAssessment,
    setDegree,
    setStatus,
    setGender,
    setSearchQuery,
    changePage,
    setOrderBy,
    resetFilter,
    setFilterFromMobile
} = filterApplicantSlice.actions;

export default filterApplicantSlice.reducer;
