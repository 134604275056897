import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatSource } from './chatRoomSlice';

export type SelectedWorkerTab = {
    user_id: string;
    full_name: string;
    profile_pict_url?: string;
    applied_position?: string;
    applied_position_id?: string;
    isMinimized: boolean;
    source?: ChatSource;
    application_id?: string;
};

type MiniChatSliceState = {
    isMessageBoxExpanded: boolean;
    selectedWorkerQueue: SelectedWorkerTab[];
};

const miniChatSlice = createSlice({
    name: 'miniChat',
    initialState: {
        /**
         * remove first element from queue if queue already consist of X item and still need
         * to add more item.
         */
        selectedWorkerQueue: [],
        isMessageBoxExpanded: false
    } as MiniChatSliceState,
    reducers: {
        openMiniChatBox: (
            state: MiniChatSliceState,
            { payload }: PayloadAction<SelectedWorkerTab>
        ) => {
            const currentQueue = state.selectedWorkerQueue;

            const isWorkerAlreadyOnQueue =
                currentQueue.filter(
                    (worker: SelectedWorkerTab) => worker.user_id === payload.user_id
                ).length > 0;

            if (isWorkerAlreadyOnQueue) {
                /**
                 * If worker already on queue, than just make sure
                 * ChatBox is not minimized
                 */
                return {
                    ...state,
                    selectedWorkerQueue: state.selectedWorkerQueue.map(
                        (worker: SelectedWorkerTab) => {
                            if (worker.user_id === payload.user_id) {
                                return { ...worker, isMinimized: false };
                            }

                            return worker;
                        }
                    )
                };
            }

            if (currentQueue.length < 2) {
                return { ...state, selectedWorkerQueue: [payload, ...state.selectedWorkerQueue] };
            }

            return {
                ...state,
                selectedWorkerQueue: [payload, ...currentQueue.slice(0, 1)]
            };
        },
        minimizeMiniChatbox: (
            state: MiniChatSliceState,
            { payload }: PayloadAction<SelectedWorkerTab>
        ) => {
            return {
                ...state,
                selectedWorkerQueue: state.selectedWorkerQueue.map((worker: SelectedWorkerTab) => {
                    if (worker.user_id === payload.user_id) {
                        return { ...worker, isMinimized: true };
                    }

                    return worker;
                })
            };
        },
        closeMiniChatBox: (
            state: MiniChatSliceState,
            { payload }: PayloadAction<SelectedWorkerTab>
        ) => {
            return {
                ...state,
                selectedWorkerQueue: state.selectedWorkerQueue.filter(
                    (worker: SelectedWorkerTab) => worker.user_id !== payload.user_id
                )
            };
        },
        setIsMessageBoxExpanded: (
            state: MiniChatSliceState,
            { payload }: PayloadAction<boolean>
        ) => {
            return { ...state, isMessageBoxExpanded: payload };
        }
    }
});

export const { openMiniChatBox, minimizeMiniChatbox, closeMiniChatBox, setIsMessageBoxExpanded } =
    miniChatSlice.actions;

export default miniChatSlice.reducer;
