import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ReferralSliceState = ReferralQueryParams;

const referralSlice = createSlice({
    name: 'referral',
    initialState: {
        page: 1
    } as ReferralSliceState,
    reducers: {
        changePage: (state: ReferralSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        }
    }
});

export const { changePage } = referralSlice.actions;

export default referralSlice.reducer;
