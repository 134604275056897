import moment from 'moment';
import config from 'redux/api/config';

export const getChatServerUrl = (): 'chat-api.lumina.mba' | 'chat-api-dev.lumina.mba' => {
    return config.CHAT_BASE_URL;
};

export const getUserJID = (userId: string): JIDUsername => {
    return `${userId}@${getChatServerUrl()}`;
};

export const renderMessageBody = (messageBody: string): string => {
    try {
        return (JSON.parse(messageBody) as unknown as MessageBody).message_text;
    } catch {
        return messageBody;
    }
};

export const renderMessageAttachment = (messageBody: string): MessageAttachment[] => {
    try {
        return (JSON.parse(messageBody) as unknown as MessageBody).media;
    } catch {
        return [];
    }
};

export const renderMessageJobOpeningCard = (messageBody: string): string[] => {
    try {
        return (JSON.parse(messageBody) as unknown as MessageBody).job_opening_ids;
    } catch {
        return [];
    }
};

export const extractPeopleFromChat = (
    messages: Message[],
    selfIdentifier: JIDUsername,
    peopleLabel: PeopleLabel
): {
    recipient: {
        id?: JIDUsername;
        full_name: string;
        profile_photo_url?: string;
    };
    sender: {
        id: JIDUsername;
        full_name: string;
        profile_photo_url?: string;
    };
} => {
    const peopleOnChat = {
        recipient: {
            full_name: peopleLabel.recipient.full_name,
            profile_photo_url: peopleLabel.recipient.profile_photo_url
        },
        sender: {
            full_name: peopleLabel.sender.full_name,
            profile_photo_url: peopleLabel.sender.profile_photo_url,
            id: selfIdentifier
        }
    };

    for (let i = 0; i < messages.length; i += 1) {
        if (messages[i].from !== selfIdentifier) {
            return {
                ...peopleOnChat,
                recipient: { id: messages[i].to, ...peopleOnChat.recipient }
            };
        }
    }

    return peopleOnChat;
};

export const makeEllipsis = (text: string, limit = 50): string => {
    if (text?.length > limit) {
        return `${text?.substring(0, limit)}...`;
    }
    return text;
};

export const renderLatestMessageBody = (messageBody: string): string => {
    try {
        const text = (JSON.parse(messageBody) as unknown as MessageBody).message_text;
        const job = (JSON.parse(messageBody) as unknown as MessageBody).job_opening_ids;

        if (text.length > 0) {
            return makeEllipsis(text, 30);
        }

        if (job.length > 0) {
            return 'Mengirim Info Lowongan Kerja';
        }

        return 'Mengirim File/Gambar';
    } catch {
        return messageBody;
    }
};

export const renderChatCardTimestamp = (time: Date): string => {
    const targetTime = moment(time);
    const now = moment();
    const countByDay = targetTime.diff(now, 'days') * -1;

    if (countByDay <= 0) {
        return targetTime.format('HH:mm');
    }

    if (countByDay === 1) {
        return 'Kemarin';
    }

    if (countByDay <= 7) {
        return targetTime.format('dddd');
    }

    return targetTime.format('D MMM');
};
