import { getWebviewFlag } from 'core/utils';
import mixpanel from 'mixpanel-browser';
import { callMoEngageApiEvent } from 'lib/moengage/client';
import * as amplitude from '@amplitude/analytics-browser';

const useTracking = (domain?: TrackingDomain): BaseTrackingFunctions => {
    const functions: BaseTrackingFunctions = {
        event(eventName, props) {
            mixpanel.track(eventName, { ...props, Platform: getWebviewFlag(), Tag: domain });
            amplitude.track(eventName, { ...props, Platform: getWebviewFlag(), Tag: domain });
        },
        identify(userId) {
            mixpanel.identify(userId);
            amplitude.setUserId(userId);
        },
        setUser(user, company) {
            mixpanel.people.set({
                $name: user?.full_name,
                $email: user?.email,
                $phone_number: user?.phone_number,
                $company_name: company ? company.name : null
            });

            const amplitudeIdentification = new amplitude.Identify();
            amplitudeIdentification.set('name', user?.full_name);
            amplitudeIdentification.set('email', user?.email);
            amplitudeIdentification.set('phone_number', user?.phone_number);
            amplitudeIdentification.set('company_name', company ? company.name : '');

            amplitude.identify(amplitudeIdentification);
        },
        moengageEvent(phoneNumber, actionName) {
            callMoEngageApiEvent(phoneNumber, actionName);
        }
    };
    return functions;
};

export default useTracking;
