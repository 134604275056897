import { baseApi } from 'redux/api/baseApi';

const APPLICANT_BASE_URL = 'jobs/company/';
const APPLICANT_BASE_URL_V2 = 'jobs/v2/company/';
const CHAT_BASE_URL = '/chats/company/applications/';

export const applicantApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllApplication: builder.query<ListResponseData<Application>, ApplicationQueryParams>({
            query: (params: ApplicationQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/`,
                params: {
                    ...params,
                    age_min: params.age_min === 0 ? '' : params.age_min,
                    age_max: params.age_max === 0 ? '' : params.age_max
                }
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: 'Application', id } as const)),
                          { type: 'Application', id: 'LIST' }
                      ]
                    : [{ type: 'Application', id: 'LIST' }]
        }),
        getAllApplicationForInfiniteScroll: builder.query<
            ListResponseData<Application>,
            ApplicationQueryParams
        >({
            query: (params: ApplicationQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/`,
                params: {
                    ...params,
                    age_min: params.age_min === 0 ? '' : params.age_min,
                    age_max: params.age_max === 0 ? '' : params.age_max
                }
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: 'Application', id } as const)),
                          { type: 'Application', id: 'LIST' }
                      ]
                    : [{ type: 'Application', id: 'LIST' }]
        }),
        getApplication: builder.query<Application, string>({
            query: (id) => `${APPLICANT_BASE_URL_V2}applications/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Application', id }],
            async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                const { data: updatedApplicant } = await queryFulfilled;

                // update is_read flag on the application list
                dispatch(
                    applicantApi.util.updateQueryData(
                        'getAllApplication',
                        (getState() as RootState).dashboard.applicant.filter,
                        (draft) => {
                            const updateData = draft?.data?.map((applicant: Application) => {
                                if (applicant.id !== updatedApplicant.id) {
                                    return applicant;
                                }
                                return {
                                    ...applicant,
                                    is_read: updatedApplicant.is_read,
                                    status: updatedApplicant.status
                                };
                            });

                            return {
                                ...draft,
                                data: updateData
                            };
                        }
                    )
                );

                // update is_read flag on the application list in mobile
                dispatch(
                    applicantApi.util.updateQueryData(
                        'getAllApplicationForInfiniteScroll',
                        (getState() as RootState).dashboard.applicant.filter,
                        (draft) => {
                            const updateData = draft?.data?.map((applicant: Application) => {
                                if (applicant.id !== updatedApplicant.id) {
                                    return applicant;
                                }
                                return {
                                    ...applicant,
                                    is_read: updatedApplicant.is_read,
                                    status: updatedApplicant.status
                                };
                            });

                            return {
                                ...draft,
                                data: updateData
                            };
                        }
                    )
                );
            }
        }),
        updateApplicationStatus: builder.mutation<
            ResponseData<Application>,
            ApplicationUpdateStatusInputData
        >({
            query: (data: ApplicationUpdateStatusInputData) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, data) => [
                ...data.job_application_ids.map(
                    (id: string) => ({ type: 'Application', id } as const)
                ),
                { type: 'Status', id: 'LIST' }
            ],
            async onQueryStarted(inputData, { dispatch, queryFulfilled, getState }) {
                const { data } = await queryFulfilled;

                const filterState: ApplicationQueryParams = (getState() as RootState).dashboard
                    .applicant.filter;
                const statusQueryParams: StatusesByApplicantQueryParams = {
                    job_opening: filterState.job_opening_id,
                    city: filterState.city,
                    degree: filterState.degree
                };

                if (data.data) {
                    dispatch(
                        applicantApi.endpoints.getApplicantStatuses.initiate(statusQueryParams, {
                            forceRefetch: true
                        })
                    );
                }
            }
        }),
        getApplicantOpening: builder.query<
            ResponseData<JobOpeningByApplicant>,
            JobOpeningByApplicantQueryParams
        >({
            query: (params: JobOpeningByApplicantQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/openings/`,
                params
            }),
            providesTags: [{ type: 'JobOpeningByApplicant', id: 'LIST' }]
        }),
        getApplicantStatuses: builder.query<
            ResponseData<StatusesByApplicant>,
            StatusesByApplicantQueryParams
        >({
            query: (params: StatusesByApplicantQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/status/`,
                params
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(
                              ({ status_type }) => ({ type: 'Status', status_type } as const)
                          ),
                          { type: 'Status', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'Status', id: 'LIST' }]
        }),
        getApplicantLocations: builder.query<
            ResponseData<LocationsByApplicant>,
            LocationsByApplicantQueryParams
        >({
            query: (params: LocationsByApplicantQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/cities/`,
                params
            })
        }),
        getApplicantJobCategories: builder.query<
            ResponseData<JobCategoriesByApplicant>,
            JobCategoriesByApplicantQueryParams
        >({
            query: (params: JobCategoriesByApplicantQueryParams) => ({
                url: `${APPLICANT_BASE_URL_V2}applications/job-categories/`,
                params
            })
        }),
        getApplicantRejectionReasonOption: builder.query<ResponseData<RejectionOption>, void>({
            query: () => `${APPLICANT_BASE_URL}rejection-reasons/`
        }),
        getApplicantAssessmentResult: builder.query<ResponseData<Assessment>, string>({
            query: (id: string) => `${APPLICANT_BASE_URL}applications/${id}/assessment-results/`
        }),
        getApplicationStatusChangeHistory: builder.query<
            JobApplicationStatusChangeHistoryResponseData,
            string
        >({
            query: (id: string) => `${APPLICANT_BASE_URL_V2}applications/${id}/change-history/`
        }),
        getApplicantChatAvailabilty: builder.query<ApplicantChatAvailabilityResponseData, string>({
            query: (id: string) => `${CHAT_BASE_URL}${id}/flags/`
        }),
        getApplicantCustomAssessmentResult: builder.query<
            ResponseData<CustomAssessmentResult>,
            string
        >({
            query: (id: string) =>
                `${APPLICANT_BASE_URL}applications/${id}/custom-assessment-results/`
        })
    }),
    overrideExisting: false
});

export const {
    useGetAllApplicationQuery,
    useGetApplicationQuery,
    useUpdateApplicationStatusMutation,
    useGetApplicantOpeningQuery,
    useLazyGetApplicantOpeningQuery,
    useGetApplicantStatusesQuery,
    useGetApplicantLocationsQuery,
    useLazyGetApplicantLocationsQuery,
    useLazyGetApplicantStatusesQuery,
    useGetApplicantJobCategoriesQuery,
    useLazyGetApplicantJobCategoriesQuery,
    useGetApplicantRejectionReasonOptionQuery,
    useLazyGetAllApplicationForInfiniteScrollQuery,
    useGetAllApplicationForInfiniteScrollQuery,
    useGetApplicantAssessmentResultQuery,
    useGetApplicationStatusChangeHistoryQuery,
    useGetApplicantChatAvailabiltyQuery,
    useGetApplicantCustomAssessmentResultQuery
} = applicantApi;
