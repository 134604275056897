import { useGetCompanyUserProfileQuery } from 'authentication/redux/api/authApi';
import { getToken } from 'authentication/redux/selectors/userSelector';
import { isNotNullAndNotUndefined } from 'core/utils';
import { useGetOnboardingStagesQuery } from 'dashboard/companyOnboarding/redux/api/onboardingApi';
import { useGetCompanyDetailQuery } from 'dashboard/profile/redux/api/profileApi';
import { useSelector } from 'react-redux';

interface CompanyUserHook extends CompanyOnboardingStages {
    isAuthenticated: boolean;
    user: User;
    company?: Company;
    isLoadingCompanyUser: boolean;
    isACompanyUser: boolean;
    isSuccessLoadOnboardingData: boolean;
    isStaffingAgencyOrOutsourcing: boolean;
}

const useCompanyUser = (): CompanyUserHook => {
    const accessToken = useSelector(getToken);
    const { data, isFetching, isLoading } = useGetCompanyUserProfileQuery(undefined, {
        refetchOnMountOrArgChange: 5000,
        skip: !isNotNullAndNotUndefined(accessToken)
    });
    const {
        data: onboardingData,
        isLoading: isLoadingOnboardingData,
        isFetching: isFetchingOnboardingData,
        isSuccess: isSuccessOnboardingData,
        isError: isErrorOnboardingData
    } = useGetOnboardingStagesQuery(undefined, {
        skip: !isNotNullAndNotUndefined(accessToken)
    });

    const checkIsACompanyUser = (): boolean => {
        if (
            data &&
            onboardingData &&
            data.company &&
            !onboardingData.is_company_rejected &&
            isNotNullAndNotUndefined(accessToken)
        ) {
            return true;
        }

        return false;
    };

    const { data: companyDetail } = useGetCompanyDetailQuery(undefined, {
        refetchOnMountOrArgChange: 5000,
        skip: !checkIsACompanyUser()
    });

    return {
        isAuthenticated: isNotNullAndNotUndefined(accessToken),
        user: data?.user as User,
        company: data?.company,
        isLoadingCompanyUser:
            isFetching || isLoading || isLoadingOnboardingData || isFetchingOnboardingData,
        isACompanyUser: checkIsACompanyUser(),
        ...(onboardingData as CompanyOnboardingStages),
        onboarding_stage: onboardingData?.onboarding_stage,
        isSuccessLoadOnboardingData: isSuccessOnboardingData || isErrorOnboardingData,
        isStaffingAgencyOrOutsourcing:
            companyDetail?.industry.name === 'Alih Daya (Outsourcing)' ||
            companyDetail?.industry.name === 'Pialang Berjangka'
    };
};
export default useCompanyUser;
