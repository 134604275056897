import { currentCompany } from 'authentication/redux/selectors/companySelector';
import { currentUser } from 'authentication/redux/selectors/userSelector';
import { getWebviewFlag } from 'core/utils';
import { useSelector } from 'react-redux';
import useTracking from './useTracking';

const useLazyAuthTracking = (domain?: TrackingDomain): BaseTrackingAuthFunctions => {
    const track = useTracking(domain);
    const user = useSelector(currentUser);
    const company = useSelector(currentCompany);

    const functions: BaseTrackingAuthFunctions = {
        event(eventName, props) {
            track.event(eventName, {
                ...props,
                CompanyUserId: user.phone_number,
                CompanyeUserName: user.full_name,
                CompanyUserEmail: user.email,
                CompanyName: company.name,
                Platform: getWebviewFlag()
            });
        },
        identify() {
            track.identify(user.phone_number);
        },
        setUser() {
            track.setUser(user, company);
        },
        moengageEvent(actionName) {
            track.moengageEvent(user.phone_number, actionName);
        }
    };
    return functions;
};

export default useLazyAuthTracking;
