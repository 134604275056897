import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNotNullAndNotUndefined } from 'core/utils';

export type UserPresceneType = 'unavailable' | 'avaiable' | 'error';

export type UserPresence = {
    id: JIDUsername;
    priority: number;
    type?: UserPresceneType;
    show?: string | 'away';
};

type UserPresenceSliceState = {
    presences: UserPresence[];
};

const userPresenceSlice = createSlice({
    name: 'userPresence',
    initialState: {
        presences: []
    } as UserPresenceSliceState,
    reducers: {
        setUserPresences: (
            state: UserPresenceSliceState,
            { payload }: PayloadAction<UserPresence>
        ) => {
            const presence = state.presences.filter((presence: UserPresence) =>
                payload.id.includes(presence.id)
            )[0];
            const oldPresences = state.presences.filter(
                (presence: UserPresence) => !payload.id.includes(presence.id)
            );

            if (isNotNullAndNotUndefined(presence)) {
                return { ...state, presences: [...oldPresences, payload] };
            }

            return { ...state, presences: [...state.presences, payload] };
        }
    }
});

export const { setUserPresences } = userPresenceSlice.actions;

export default userPresenceSlice.reducer;
