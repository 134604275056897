import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import router from 'next/router';
import {
    HAS_JOB_APPLICATION_KEY,
    ONBOARDING_STAGES_KEY,
    COMPANY_REJECTION_REASON_KEY
} from 'authentication/constant';
import { PERSIST_FORM_KEY } from 'dashboard/companyOnboarding/contexts/CompanyOnboardingContext';
import { isNotNullAndNotUndefined } from 'core/utils';
import { removeUser } from 'authentication/redux/slices/userSlice';

const IGNORED_ERROR_MESSAGE_ENDPOINT_NAME = ['getJobCard'];

/**
 * Log a warning and show a toast!
 */

export const apiErrorHandler: Middleware = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        try {
            const { status } = action.payload;

            if (IGNORED_ERROR_MESSAGE_ENDPOINT_NAME.includes(action.meta.arg.endpointName)) {
                return next(action);
            }

            if (status === 'PARSING_ERROR') {
                const { originalStatus } = action.payload;

                if (originalStatus === 404) {
                    toast.error('Data yang Anda cari tidak ditemukan', {
                        position: 'top-center',
                        theme: 'colored',
                        hideProgressBar: true,
                        toastId: originalStatus
                    });
                    router.push('/404');
                }
            } else if (status !== 'FETCH_ERROR') {
                const { errors } = action.payload.data as LuminaError;

                if (
                    errors.code === 'authentication_failed' &&
                    isNotNullAndNotUndefined(window.localStorage.getItem('token'))
                ) {
                    toast.error('Sesi Anda sudah habis, mohon Login kembali', {
                        position: 'top-center',
                        theme: 'colored',
                        hideProgressBar: true,
                        toastId: status
                    });
                    window.localStorage.removeItem('persist:company');
                    window.localStorage.removeItem('persist:user');
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem(PERSIST_FORM_KEY);
                    window.localStorage.removeItem(HAS_JOB_APPLICATION_KEY);
                    window.localStorage.removeItem(ONBOARDING_STAGES_KEY);
                    window.localStorage.removeItem(COMPANY_REJECTION_REASON_KEY);
                    next(removeUser());
                }

                if (errors.validation_error === null) {
                    toast.error(errors.error_message, {
                        position: 'top-center',
                        theme: 'colored',
                        hideProgressBar: true,
                        toastId: status
                    });
                } else {
                    Object.keys(errors.validation_error).map((key) => {
                        if (Array.isArray(errors.validation_error[key])) {
                            toast.error(`${key} : ${errors.validation_error[key][0]}`, {
                                position: 'top-center',
                                theme: 'colored',
                                hideProgressBar: true,
                                toastId: key
                            });
                        } else {
                            toast.error(`${key} : ${errors.validation_error[key]}`, {
                                position: 'top-center',
                                theme: 'colored',
                                hideProgressBar: true,
                                toastId: key
                            });
                        }
                    });
                }
            } else {
                toast.error('Terjadi kesalahan pada server, mohon coba beberapa saat lagi', {
                    position: 'top-center',
                    theme: 'colored',
                    hideProgressBar: true,
                    toastId: 'SERVER ERROR'
                });
            }
        } catch (e) {
            toast.error('Terjadi kesalahan pada server, mohon coba beberapa saat lagi', {
                position: 'top-center',
                theme: 'colored',
                hideProgressBar: true,
                toastId: 'SERVER ERROR'
            });
        }
    }

    return next(action);
};
