import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onboardingApi } from 'dashboard/companyOnboarding/redux/api/onboardingApi';

export type JobOpeningUIConfigurationState = {
    view: null | 'list' | 'grid';
    showConfetti: boolean;
    showShadow: boolean;
};

const uiConfigurationSlices = createSlice({
    name: 'jobOpeningUIConfoguration',
    initialState: {
        view: 'list',
        showConfetti: false,
        showShadow: true
    } as JobOpeningUIConfigurationState,
    reducers: {
        setViewConfig: (
            state: JobOpeningUIConfigurationState,
            { payload }: PayloadAction<null | 'list' | 'grid'>
        ) => {
            return { ...state, view: payload };
        },
        setShowConfetti: (
            state: JobOpeningUIConfigurationState,
            { payload }: PayloadAction<boolean>
        ) => {
            return { ...state, showConfetti: payload };
        },
        setShadow: (state: JobOpeningUIConfigurationState, { payload }: PayloadAction<boolean>) => {
            return { ...state, showShadow: payload };
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            onboardingApi.endpoints.getOnboardingStages.matchFulfilled,
            (state, { payload }: PayloadAction<CompanyOnboardingStages>) => {
                if (payload.onboarding_stage === 'has_verified_job_opening') {
                    state.showConfetti = true;
                }

                return state;
            }
        );
    }
});

export const { setViewConfig, setShowConfetti, setShadow } = uiConfigurationSlices.actions;

export default uiConfigurationSlices.reducer;
