import Router from 'next/router';
import { useEffect } from 'react';

const useNetwork = (): void => {
    if (typeof window !== undefined) {
        const handleOnline = (): void => {
            console.log('online');
            Router.push('/');
        };
        const handleOffline = (): void => {
            console.log('offline');
            Router.push('/offline.html');
        };

        useEffect(() => {
            window.addEventListener('offline', handleOffline);
            window.addEventListener('online', handleOnline);

            if (!navigator.onLine) {
                Router.push('/offline.html');
            }

            return () => {
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);
            };
        }, []);
    }
};
export default useNetwork;
