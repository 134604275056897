import { baseApi } from 'redux/api/baseApi';

const CORE_BASE_URL = 'cores/company/';
const COMMONS_BASE_URL = 'commons/';

export const coreApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFeatureFlag: builder.query<ATSFeatureFlag, void>({
            query: () => `${CORE_BASE_URL}feature-flags/`,
            providesTags: ['FeatureFlag']
        }),
        uploadFile: builder.mutation<ResponseData<UploadFileResponseData>, UploadFileInputData>({
            query: (data: UploadFileInputData) => ({
                url: `${COMMONS_BASE_URL}generate-presigned-upload-urls/`,
                body: data,
                method: 'POST'
            })
        })
    }),
    overrideExisting: false
});

export const { useGetFeatureFlagQuery, useUploadFileMutation } = coreApi;
