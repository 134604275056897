import { combineReducers } from '@reduxjs/toolkit';
import filterApplicantReducer from './slices/filterApplicantSlices';
import filterRecommendedWorkerReducer from './slices/filterRecommendedWorkerSlices';
import mobileFilterApplicantReducer from './slices/mobileFilterApplicantSlices';
import selectApplicantReducer from './slices/selectApplicantSlice';
import uiConfigurationReducer from './slices/uiConfigurationSlices';

const applicantReducer = combineReducers({
    selection: selectApplicantReducer,
    /**
     * The reason behind why right now we are using two different filter reducer
     * on desktop and mobile was because the different filter flow requirement.
     *
     * on Desktop, every user click filter option, it will simultanously filtering the data,
     * but on mobile, user should pick filter option first, then if already done, user will
     * click "terapkan" button, when this button clicked, then filter will executed
     */
    filter: filterApplicantReducer,
    mobileFilter: mobileFilterApplicantReducer,
    recommendedWorkerfilter: filterRecommendedWorkerReducer,
    uiConfiguration: uiConfigurationReducer
});

export default applicantReducer;
