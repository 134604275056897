/* eslint-disable @typescript-eslint/ban-ts-comment */
import Head from 'next/head';
import type { AppProps } from 'next/app';
import 'styles/tailwindcss/global.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';

import { PersistGate } from 'redux-persist/integration/react';

import theme from 'styles/mui/theme';
import createEmotionCache from 'styles/mui/createEmotionCache';
import useStore, { wrapper } from 'redux/store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mixpanel from 'mixpanel-browser';
import * as amplitude from '@amplitude/analytics-browser';
import 'moment/locale/id';
import LuminaBackdrop from 'core/components/LuminaBackdrop';
import { StanzaProvider } from 'dashboard/chat/contexts/StanzaProvider';
import { OnboardingProvider } from 'dashboard/contexts/OnboardingContext';
import { useEffect } from 'react';
import useNetwork from 'core/hooks/useNetwork';
import { GigWorkLeadGenProvider } from 'dashboard/gigWork/contexts/GigWorkLeadGenContext';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || 'NO_TOKEN');
amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_TOKEN || 'NO_TOKEN');

const clientSideEmotionCache = createEmotionCache();
const store = useStore();

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
    useNetwork();

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function () {
                navigator.serviceWorker.register('/sw.js').then(
                    function (registration) {
                        console.log(
                            'Service Worker registration successful with scope: ',
                            registration.scope
                        );
                    },
                    function (err) {
                        console.log('Service Worker registration failed: ', err);
                    }
                );
            });
        }
    }, []);

    return (
        <CacheProvider value={clientSideEmotionCache}>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <title>Lumina | Application Tracking System</title>
                <meta name="description" content="Application Tracking System by Lumina" />
            </Head>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* @ts-ignore */}
                <PersistGate persistor={store.__persistor} loading={<LuminaBackdrop />}>
                    <StanzaProvider>
                        <OnboardingProvider>
                            <GigWorkLeadGenProvider>
                                <Component {...pageProps} />
                            </GigWorkLeadGenProvider>
                        </OnboardingProvider>
                    </StanzaProvider>
                </PersistGate>
            </ThemeProvider>
            <ToastContainer />
        </CacheProvider>
    );
};

export default wrapper.withRedux(MyApp);
