import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StorageConfigState = {
    isPersistStorageStoreHydrated: boolean;
};

const storageConfigSlices = createSlice({
    name: 'core',
    initialState: {
        isPersistStorageStoreHydrated: false
    },
    reducers: {
        setPersistStorageHydrated: (
            state: StorageConfigState,
            { payload }: PayloadAction<boolean>
        ) => {
            return { ...state, isPersistStorageStoreHydrated: payload };
        }
    }
});

export const { setPersistStorageHydrated } = storageConfigSlices.actions;

export default storageConfigSlices.reducer;
