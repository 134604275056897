import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';
import { isNotNullAndNotUndefined } from 'core/utils';
import { DashboardFullRoute } from 'dashboard/constant';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { ReceivedMessage } from 'stanza/protocol';
import { useLazyGetWorkerChatProfileQuery } from '../redux/api/chatApi';
import { getAllRoomInboxCounter } from '../redux/selectors/chatInboxSelector';
import {
    addGlobalInboxCounter,
    addRoomInboxCounter,
    setRoomInboxCounter,
    substractGlobalInboxCounter
} from '../redux/slices/chatInboxSlice';
import { renderLatestMessageBody } from '../utils';

const useInbox = () => {
    const dispatch = useDispatch();
    const [fetchWorkerChatProfile] = useLazyGetWorkerChatProfileQuery();
    const router = useRouter();
    const allRoomInbox = useSelector(getAllRoomInboxCounter);
    const { isDesktopBreakpoints } = useWindowBreakpoints();
    const track = useLazyAuthTracking('CHAT');

    const createNotificationBanner = async (
        userId: string,
        message: ReceivedMessage
    ): Promise<void> => {
        const workerProfileData = await fetchWorkerChatProfile(userId);

        if (workerProfileData.data) {
            const notification = new Notification(
                `Pesan dari: ${workerProfileData.data.full_name}`,
                {
                    body: renderLatestMessageBody(message.body as string),
                    badge: 'https://djhutyrz7bno6.cloudfront.net/assets/lumina_logo.png',
                    icon: 'https://djhutyrz7bno6.cloudfront.net/assets/lumina_logo.png'
                }
            );

            notification.onclick = (event: Event) => {
                event.preventDefault();
                dispatch(setRoomInboxCounter({ id: userId, count: 0 }));
                dispatch(substractGlobalInboxCounter(allRoomInbox[userId] as number));
                if (isDesktopBreakpoints) {
                    router.push(`${DashboardFullRoute.chat}/?id=${userId}`);
                } else {
                    router.push(`${DashboardFullRoute.chat}/${userId}`);
                }
            };
        }
    };

    const addNewInboxData = (userId: string, messageId: string): boolean => {
        const inboxMesageIdsStorage = sessionStorage.getItem('messageIds');
        const duplicateCounterStorage = sessionStorage.getItem('duplicateCounter');
        let inboxMessageIds = {} as MessageIds;
        let duplicateCounter = 0;

        if (isNotNullAndNotUndefined(inboxMesageIdsStorage)) {
            inboxMessageIds = JSON.parse(inboxMesageIdsStorage as string) as MessageIds;
        }

        if (isNotNullAndNotUndefined(duplicateCounterStorage)) {
            duplicateCounter = Number(duplicateCounterStorage);
        }

        if (!inboxMessageIds[messageId]) {
            dispatch(addGlobalInboxCounter({ id: userId, count: 1 }));
            dispatch(addRoomInboxCounter({ id: userId, count: 1 }));
            sessionStorage.setItem(
                'messageIds',
                JSON.stringify({ ...inboxMessageIds, [messageId]: true })
            );

            return true;
        } else {
            if (duplicateCounter > 3) {
                track.event('Duplicate Message Received More than 3 Error');
                sessionStorage.removeItem('duplicateCounter');
            } else {
                duplicateCounter = duplicateCounter + 1;
                sessionStorage.setItem('duplicateCounter', duplicateCounter.toString());
            }
        }
        return false;
    };

    return { addNewInboxData, createNotificationBanner };
};

export default useInbox;
