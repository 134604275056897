const config = {
    prod: {
        API_BASE_URL: 'https://api.lumina.mba/',
        ES_BASE_URL: 'https://search.lumina.mba/',
        CHAT_BASE_URL: 'chat-api.lumina.mba'
    },
    dev: {
        API_BASE_URL: 'https://api-dev.lumina.mba/',
        ES_BASE_URL: 'https://search-dev.lumina.mba/',
        CHAT_BASE_URL: 'chat-api-dev.lumina.mba'
    },
    local: {
        API_BASE_URL: 'http://localhost:8000/',
        ES_BASE_URL: 'https://search.lumina.mba/',
        CHAT_BASE_URL: 'chat-api-dev.lumina.mba'
    }
};

export default {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...config[process.env.NEXT_PUBLIC_NODE_ENV || 'local']
};
