import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
    useForm,
    Control,
    FieldErrors,
    UseFormWatch,
    UseFormSetValue,
    UseFormHandleSubmit,
    UseFormClearErrors,
    FormState
} from 'react-hook-form';
import { useCreateCompanyMutation } from '../redux/api/onboardingApi';
import { FileInputResponse } from 'core/components/Input/UploadInput';
import useWindowBreakpoints from 'core/hooks/useWindowBreakpoints';

export type CompanyOnboardingInputForm = {
    control: Control<CompanyOnboardingInputData, any>;
    errors: FieldErrors<CompanyOnboardingInputData>;
    disabled?: boolean;
    watch?: UseFormWatch<CompanyOnboardingInputData>;
    setValue?: UseFormSetValue<CompanyOnboardingInputData>;
};

export const OnboardingStagePrecedence: { [key in CompanyOnboardingStage]: number } = {
    has_signed_up: 0,
    has_filled_company_data: 1,
    has_add_job_opening: 2,
    has_created_job_opening: 3,
    has_verified_job_opening: 4,
    finish_onboarding: 5
};

type CompanyOnboardingContextType = {
    handleSubmit: UseFormHandleSubmit<CompanyOnboardingInputData>;
    control: Control<CompanyOnboardingInputData, any>;
    errors: FieldErrors<CompanyOnboardingInputData>;
    watch: UseFormWatch<CompanyOnboardingInputData>;
    setValue: UseFormSetValue<CompanyOnboardingInputData>;
    step: number;
    nextStep: () => void;
    prevStep: () => void;
    isLastStep: () => boolean;
    onSubmit: (data: CompanyOnboardingInputData) => void;
    isLoading: boolean;
    isDesktopOnboarding: boolean;
    clearErrors: UseFormClearErrors<CompanyOnboardingInputData>;
    formState: FormState<CompanyOnboardingInputData>;
    documentMetadata: { [key: string]: FileInputResponse };
    setDocumentMetadata: (metadata: { [key: string]: FileInputResponse }) => void;
    setInnerBackAction: (func: () => void) => void;
    setInnerNextAction: (func: () => void) => void;
    shouldAllowNextStep: boolean;
    setShouldAllowNextStep: (b: boolean) => void;
    shouldInquireCompanyExistence: boolean;
    setShouldInquireCompanyExistence: (b: boolean) => void;
    checkAndUpdateInquireCompanyExistence: () => void;
};

const CompanyOnboardingContext = createContext<CompanyOnboardingContextType>(
    {} as CompanyOnboardingContextType
);

export const PERSIST_FORM_KEY = 'persist_user_onboarding';

const getDefaultValues = (): CompanyOnboardingInputData => {
    const persistData = window.localStorage.getItem(PERSIST_FORM_KEY);
    if (persistData) {
        const parsedData = JSON.parse(persistData);
        if (parsedData.siup_path) delete parsedData['siup_path'];
        if (parsedData.npwp_path) delete parsedData['npwp_path'];
        return parsedData;
    }

    return {
        name: '',
        year_founded: '',
        head_count: '-',
        industry_id: '',
        industry_name: '',
        address: '',
        address_detail: '',
        google_place_id: '',
        is_outsourcing_company: 'false',
        branch_type: '',
        description: '',
        facebook_url: '',
        instagram_url: '',
        linkedin_url: '',
        website_url: '',
        tiktok_url: ''
    } as CompanyOnboardingInputData;
};

export const CompanyOnboardingProvider = ({
    children
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const MAX_FORM_STEP_DESKTOP = 5;
    const MAX_FORM_STEP_MOBILE = 8;
    const MIN_FORM_STEP = 1;
    const { handleSubmit, control, watch, setValue, trigger, formState, clearErrors } = useForm({
        defaultValues: getDefaultValues()
    });
    const [step, setStep] = useState<number>(MIN_FORM_STEP);
    const [shouldAllowNextStep, setShouldAllowNextStep] = useState<boolean>(true);
    const [createCompany, { isLoading }] = useCreateCompanyMutation();
    const allData = watch();
    const track = useLazyAuthTracking('COMPANY REGISTRATION');
    const [documentMetadata, setDocumentMetadata] = useState({});
    const [shouldInquireCompanyExistence, setShouldInquireCompanyExistence] =
        useState<boolean>(false);

    const { isDesktopBreakpoints } = useWindowBreakpoints();
    const [isDesktopOnboarding, setIsDesktopOnboarding] = useState<boolean>(isDesktopBreakpoints);

    const [innerBackAction, setInnerBackAction] = useState<() => void | undefined>();
    const [innerNextAction, setInnerNextAction] = useState<() => void | undefined>();

    const [resizeCount, setResizeCount] = useState(0);

    useEffect(() => {
        if (resizeCount <= 1) {
            setIsDesktopOnboarding(isDesktopBreakpoints);
            setResizeCount(resizeCount + 1);
        }
    }, [isDesktopBreakpoints]);

    useEffect(() => {
        window.localStorage.setItem(PERSIST_FORM_KEY, JSON.stringify(allData));
    }, [allData]);

    const watchIndustryName = watch('industry_name');

    const checkAndUpdateInquireCompanyExistence = (): boolean => {
        if (
            watchIndustryName === 'Alih Daya (Outsourcing)' ||
            watchIndustryName === 'Pialang Berjangka'
        ) {
            setShouldInquireCompanyExistence(true);
            return true;
        } else {
            setShouldInquireCompanyExistence(false);
            return false;
        }
    };

    useEffect(() => {
        if (watchIndustryName) {
            checkAndUpdateInquireCompanyExistence();
        }
    }, [watchIndustryName]);

    const VALIDATE_STEP_DESKTOP = [
        ['industry_id'],
        ['name', 'brand', 'logo_url'],
        ['description', 'year_founded', 'head_count'],
        [
            'address',
            'branch_type',
            'website_url',
            'facebook_url',
            'instagram_url',
            'linkedin_url',
            'tiktok_url'
        ],
        ['npwp_path', 'siup_path']
    ];

    const VALIDATE_STEP_MOBILE = [
        ['industry_id'],
        ['name', 'brand'],
        ['logo_url'],
        ['description'],
        ['year_founded', 'head_count'],
        ['address', 'branch_type'],
        ['website_url', 'facebook_url', 'instagram_url', 'linkedin_url', 'tiktok_url'],
        ['npwp_path', 'siup_path']
    ];

    const nextStep = async (): Promise<void> => {
        if (innerNextAction) {
            const nextSuccess = innerNextAction();
            if (nextSuccess) {
                return;
            }
        }

        const VALIDATE_STEPS = isDesktopOnboarding ? VALIDATE_STEP_DESKTOP : VALIDATE_STEP_MOBILE;
        const isValid = await trigger(
            VALIDATE_STEPS[step - 1] as unknown as keyof CompanyOnboardingInputData
        );

        if (
            step + 1 <= (isDesktopOnboarding ? MAX_FORM_STEP_DESKTOP : MAX_FORM_STEP_MOBILE) &&
            isValid
        ) {
            setStep(step + 1);
            setInnerBackAction(undefined);
            track.event('Click "Selanjutnya" button on Onboarding Form Page' + step);
        }
    };

    const prevStep = (): void => {
        if (innerBackAction) {
            const backSuccess = innerBackAction();
            if (backSuccess) {
                return;
            }
        }

        if (step - 1 >= MIN_FORM_STEP) {
            setStep(step - 1);
            setShouldAllowNextStep(true);
            setInnerNextAction(undefined);
            track.event('Click "Kembali" button on Onboarding Form Page' + step);
        }
    };

    const isLastStep = (): boolean => {
        return step === (isDesktopOnboarding ? MAX_FORM_STEP_DESKTOP : MAX_FORM_STEP_MOBILE);
    };

    const cleanInputData = (data: LuminaObject, key: string, comparison = '-'): string | null => {
        return data[key] === comparison ? null : data[key];
    };

    const onSubmit = (data: CompanyOnboardingInputData): void => {
        window.localStorage.removeItem(PERSIST_FORM_KEY);
        if (
            (data?.instagram_url?.trim() ||
                data?.facebook_url?.trim() ||
                data?.linkedin_url?.trim() ||
                data?.tiktok_url?.trim() ||
                data?.website_url) &&
            !isLoading
        ) {
            createCompany({
                ...data,
                logo_url: cleanInputData(data, 'logo_url', ''),
                brand: cleanInputData(data, 'brand', ''),
                website_url: cleanInputData(data, 'website_url', '')
                    ? `https://www.${data.website_url?.trim()}`
                    : null,
                facebook_url: cleanInputData(data, 'facebook_url', '')
                    ? `https://facebook.com/${data.facebook_url?.trim()}`
                    : null,
                linkedin_url: cleanInputData(data, 'linkedin_url', '')
                    ? `https://linkedin.com/company/${data.linkedin_url?.trim()}`
                    : null,
                instagram_url: cleanInputData(data, 'instagram_url', '')
                    ? `https://instagram.com/${data.instagram_url?.trim()}`
                    : null,
                tiktok_url: cleanInputData(data, 'tiktok_url', '')
                    ? `https://tiktok.com/@${data.tiktok_url?.trim()}`
                    : null,
                address: `${data.address}. ${data.address_detail}`
            });

            track.event('Click Save Button on Onboarding Form', {
                CompanyName: data.name,
                IndustryID: data.industry_id,
                Headcount: data.head_count,
                YearFounded: data.year_founded,
                Address: data.address,
                IsBranchCompany: data.branch_type,
                IsOutsourcing: data.is_outsourcing_company
            });
        }
    };

    const memoedValue = useMemo(
        () => ({
            handleSubmit,
            control,
            errors: formState.errors,
            watch,
            setValue,
            nextStep,
            prevStep,
            documentMetadata,
            setDocumentMetadata,
            setInnerBackAction,
            setInnerNextAction,
            setShouldAllowNextStep,
            setShouldInquireCompanyExistence,
            shouldInquireCompanyExistence,
            checkAndUpdateInquireCompanyExistence,
            shouldAllowNextStep,
            isLastStep,
            step,
            onSubmit,
            isLoading,
            isDesktopOnboarding,
            clearErrors,
            formState
        }),
        [
            control,
            formState.errors,
            step,
            isLoading,
            formState,
            documentMetadata,
            isDesktopOnboarding,
            innerBackAction,
            innerNextAction
        ]
    );

    return (
        <CompanyOnboardingContext.Provider value={memoedValue}>
            {children}
        </CompanyOnboardingContext.Provider>
    );
};

export const useCompanyOnboarding = (): CompanyOnboardingContextType => {
    return useContext(CompanyOnboardingContext);
};

export default useCompanyOnboarding;
