import { baseApi } from 'redux/api/baseApi';

export const chatApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkerChatProfile: builder.query<WorkerChatProfile, string>({
            query: (userId: string) => ({
                url: `/workers/users/${userId}/chat-profile/`
            })
        }),
        getActiveChats: builder.query<ListResponseData<WorkerChatProfile>, ActiveChatQueryParams>({
            query: (params: ActiveChatQueryParams) => ({
                url: 'chats/company/',
                params
            }),
            providesTags: ['WorkerChat']
        }),
        initActiveChat: builder.mutation<InitWorkerChatResponseData, InitWorkerChat>({
            query: (data: InitWorkerChat) => ({
                url: 'chats/company/',
                body: data,
                method: 'POST'
            }),
            invalidatesTags: [{ type: 'WorkerChat', id: 'LIST' }]
        }),
        initEngageRecommendedWorkerChat: builder.mutation<
            InitRecommendedWorkerChatResponseData,
            InitRecommendedWorkerChat
        >({
            query: (data: InitRecommendedWorkerChat) => ({
                url: '/jobs/company/recommended-workers/engage/',
                body: data,
                method: 'POST'
            })
        }),
        getJobCard: builder.query<JobOpeningCard, string>({
            query: (jobId: string) => ({
                url: `/jobs/${jobId}/chat/`
            })
        }),
        getQuickReply: builder.query<QuickRepliesResponseData, void>({
            query: () => ({
                url: 'chats/company/quick-replies/'
            })
        })
    }),
    overrideExisting: false
});

export const {
    useGetWorkerChatProfileQuery,
    useGetActiveChatsQuery,
    useLazyGetActiveChatsQuery,
    useInitActiveChatMutation,
    useInitEngageRecommendedWorkerChatMutation,
    useLazyGetWorkerChatProfileQuery,
    useGetJobCardQuery,
    useGetQuickReplyQuery
} = chatApi;
