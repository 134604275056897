import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { standardizeDate } from 'core/utils';
import { commonSetState } from 'dashboard/redux/utils';

export enum GigWorkOrderBy {
    descending = '-created_at',
    ascending = 'created_at'
}

export type GigWorkSliceState = BaseListQueryParams & {
    fulfillment_status: string[];
    job_category_id: string[];
    start_at: string;
    order_by: GigWorkOrderBy;
    district_id: string[];
};

const gigWorkSlice = createSlice({
    name: 'gigWork',
    initialState: {
        page: 1,
        fulfillment_status: [],
        job_category_id: [],
        district_id: [],
        start_at: '',
        order_by: GigWorkOrderBy.descending
    } as GigWorkSliceState,
    reducers: {
        changePage: (state: GigWorkSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        },
        setSearchQuery: (state: GigWorkSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                page: 1,
                search_query: payload
            };
        },
        setJobCategory: (state: GigWorkSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.job_category_id, 'job_category_id', payload);
        },
        setDistrict: (state: GigWorkSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.district_id, 'district_id', payload);
        },
        setStatus: (state: GigWorkSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.fulfillment_status, 'fulfillment_status', payload);
        },
        setOrderBy: (state: GigWorkSliceState, { payload }: PayloadAction<GigWorkOrderBy>) => {
            return {
                ...state,
                order_by: payload,
                page: 1
            };
        },
        setStartAt: (state: GigWorkSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                page: 1,
                start_at: payload
            };
        },
        setAllDate: (state: GigWorkSliceState) => {
            return {
                ...state,
                start_at: '',
                page: 1
            };
        },
        setToday: (state: GigWorkSliceState) => {
            return {
                ...state,
                start_at: standardizeDate(new Date()),
                page: 1
            };
        }
    }
});

export const {
    changePage,
    setSearchQuery,
    setJobCategory,
    setDistrict,
    setStatus,
    setOrderBy,
    setStartAt,
    setAllDate,
    setToday
} = gigWorkSlice.actions;

export default gigWorkSlice.reducer;
