import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ApplicantUIConfigurationState = {
    mobileApplicantListHeight: number;
    showApplicantContainer: boolean;
};

const uiConfigurationSlices = createSlice({
    name: 'jobOpeningUIConfoguration',
    initialState: {
        mobileApplicantListHeight: 700,
        showApplicantContainer: true
    } as ApplicantUIConfigurationState,
    reducers: {
        setApplicantListHeight: (
            state: ApplicantUIConfigurationState,
            { payload }: PayloadAction<number>
        ) => {
            return { ...state, mobileApplicantListHeight: payload };
        },
        setShowApplicantContainer: (
            state: ApplicantUIConfigurationState,
            { payload }: PayloadAction<boolean>
        ) => {
            return { ...state, showApplicantContainer: payload };
        }
    }
});

export const { setApplicantListHeight, setShowApplicantContainer } = uiConfigurationSlices.actions;

export default uiConfigurationSlices.reducer;
