import CircularProgress from '@mui/material/CircularProgress';
import { getATSVersion } from 'core/utils';

const LuminaBackdrop = (): JSX.Element => {
    return (
        <div className="w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-100 flex-col">
            <div className="text-sky-600 mb-2">
                <CircularProgress color="inherit" />
            </div>
            <img
                src="https://djhutyrz7bno6.cloudfront.net/assets/full_logo.svg"
                alt="logo"
                width="120px"
                className="mb-4"
            />
            <span className="text-[8px] text-center">{getATSVersion()}</span>
        </div>
    );
};

export default LuminaBackdrop;
