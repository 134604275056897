import { Agent } from 'stanza';
import { mergeFields } from 'stanza/helpers/DataForms';
import { DataForm, DataFormField, Delay, IQ, Message, ReceivedMessage } from 'stanza/protocol';
import Protocol, {
    InboxQuery,
    InboxFin,
    InboxResult,
    InboxAll,
    InboxQueryResult,
    LatestMessage
} from './protocol';

declare module 'stanza' {
    export interface Agent {
        searchInbox(jid: string, opts: Partial<InboxQueryOptions>): Promise<InboxFin>;
    }

    export interface AgentEvents {
        inbox: InboxResult;
    }

    // 5. Stanza definitions MUST be placed in the Stanzas namespace
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace Stanzas {
        // 6. Attach our new definition to Message stanzas
        export interface Message {
            inbox?: InboxQueryResult;
        }
    }
}

export interface InboxQueryOptions extends InboxQuery {
    with?: string;
    hidden?: boolean;
    start?: Date;
    end?: Date;
}

export default function (client: Agent): void {
    client.stanzas.define(Protocol);

    client.searchInbox = async (jid, opts: Partial<InboxQueryOptions> = {}) => {
        const queryid = client.nextId();

        opts.queryId = queryid;

        const form: DataForm = opts.form || {};
        form.type = 'submit';
        const fields = form.fields || [];

        const defaultFields: DataFormField[] = [
            {
                name: 'FORM_TYPE',
                type: 'hidden',
                value: 'erlang-solutions.com:xmpp:inbox:0'
            },
            {
                name: 'hidden_read',
                type: 'text-single',
                value: opts.hidden ? 'true' : 'false'
            }
        ];
        if (opts.with) {
            defaultFields.push({
                name: 'with',
                type: 'text-single',
                value: opts.with
            });
        }
        if (opts.start) {
            defaultFields.push({
                name: 'start',
                type: 'text-single',
                value: opts.start.toISOString()
            });
        }
        if (opts.end) {
            defaultFields.push({
                name: 'end',
                type: 'text-single',
                value: opts.end.toISOString()
            });
        }

        form.fields = mergeFields(defaultFields, fields);
        opts.form = form;

        let results: InboxResult[] = [];
        const latestMessageResults: LatestMessage[] = [];

        const collectLatestMessage = (msg: Message): void => {
            let fromId: string | null | undefined = null;
            let toId: string | null | undefined = null;

            if (msg.inbox?.item.from) {
                fromId = msg.inbox?.item.from;
            } else {
                fromId = msg.inbox?.item.message?.from;
            }

            if (msg.inbox?.item.to) {
                toId = msg.inbox?.item.to;
            } else {
                toId = msg.inbox?.item.message?.to;
            }

            if (msg.inbox?.item.message) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                latestMessageResults.push({
                    ...msg.inbox?.item.message,
                    delay: msg.inbox.item.delay as Delay,
                    sent: new Date(msg.inbox?.item.delay?.timestamp as unknown as string),
                    partnerId: fromId === jid ? (toId as string) : (fromId as string)
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                latestMessageResults.push({
                    ...msg.inbox?.item,
                    sent: new Date(msg.inbox?.item.delay?.timestamp as unknown as string),
                    partnerId: fromId === jid ? (toId as string) : (fromId as string)
                });
            }
        };

        const collector = (msg: Message): void => {
            let fromId: string | null | undefined = null;

            if (msg.inbox?.item.from) {
                fromId = msg.inbox?.item.from;
            } else {
                fromId = msg.inbox?.item.message?.from;
            }

            const currentResult = results.filter(
                (inbox: InboxResult) => inbox.from === (fromId as string)
            );
            const currentResultExceptFromId = results.filter(
                (inbox: InboxResult) => inbox.from !== (fromId as string)
            );

            if (fromId !== jid) {
                if (currentResult.length > 0) {
                    currentResultExceptFromId.push({
                        ...currentResult[0],
                        unread: currentResult[0].unread + (msg.inbox?.unread ?? 0)
                    });

                    results = currentResultExceptFromId;
                } else {
                    results.push({
                        unread: msg.inbox?.unread as number,
                        from: fromId as string
                    });
                }
            }
        };

        client.on('inbox', collector);
        client.on('inbox', collectLatestMessage);

        try {
            const resp = await client.sendIQ<IQ & { inbox: InboxQuery }, IQ & { inbox: InboxAll }>({
                inbox: opts,
                id: queryid,
                type: 'set'
            });

            return {
                type: 'result',
                inbox: resp.inbox,
                roomInbox: results,
                latestMessages: latestMessageResults
            };
        } finally {
            client.off('inbox', collector);
        }
    };

    client.on('message', (msg: ReceivedMessage) => {
        if (msg.inbox) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            client.emit('inbox', msg);
        }
    });
}
