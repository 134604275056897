import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ChatInboxSliceState = {
    global: number;
    room: { [id: string]: number };
    inboxBlacklist: string[];
};

const chatInboxSlice = createSlice({
    name: 'chatInbox',
    initialState: {
        global: 0,
        room: {},
        inboxBlacklist: []
    } as ChatInboxSliceState,
    reducers: {
        setGlobalInboxCounter: (state: ChatInboxSliceState, { payload }: PayloadAction<number>) => {
            return { ...state, global: payload };
        },
        addGlobalInboxCounter: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<{ id: string; count: number }>
        ) => {
            if (state.inboxBlacklist.includes(payload.id)) {
                return state;
            }
            return { ...state, global: state.global + payload.count };
        },
        substractGlobalInboxCounter: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<number>
        ) => {
            return { ...state, global: state.global - payload <= 0 ? 0 : state.global - payload };
        },
        setRoomInboxCounter: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<{ id: string; count: number }>
        ) => {
            return {
                ...state,
                room: {
                    ...state.room,
                    [payload.id]: payload.count
                }
            };
        },
        addRoomInboxCounter: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<{ id: string; count: number }>
        ) => {
            if (state.inboxBlacklist.includes(payload.id)) {
                return state;
            }

            if (!state.room[payload.id]) {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        [payload.id]: payload.count
                    }
                };
            }

            const currentCounter = state.room[payload.id];

            return {
                ...state,
                room: {
                    ...state.room,
                    [payload.id]: currentCounter + payload.count
                }
            };
        },
        substractRoomInboxCounter: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<{ id: string; count: number }>
        ) => {
            if (!state.room[payload.id]) {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        [payload.id]: 0
                    }
                };
            }

            const currentCounter = state.room[payload.id];

            return {
                ...state,
                room: {
                    ...state.room,
                    [payload.id]:
                        currentCounter - payload.count <= 0 ? 0 : currentCounter - payload.count
                }
            };
        },
        addUserIdToInboxBlacklist: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<string>
        ) => {
            if (state.inboxBlacklist.indexOf(payload) < 0) {
                return { ...state, inboxBlacklist: [...state.inboxBlacklist, payload] };
            }

            return state;
        },
        removeUserIdToInboxBlacklist: (
            state: ChatInboxSliceState,
            { payload }: PayloadAction<string>
        ) => {
            return {
                ...state,
                inboxBlacklist: state.inboxBlacklist.filter((id: string) => payload !== id)
            };
        },
        removeAllUserIdFromInboxBlacklist: (state: ChatInboxSliceState) => {
            return { ...state, inboxBlacklist: [] };
        }
    }
});

export const {
    setGlobalInboxCounter,
    setRoomInboxCounter,
    addGlobalInboxCounter,
    addRoomInboxCounter,
    substractGlobalInboxCounter,
    substractRoomInboxCounter,
    addUserIdToInboxBlacklist,
    removeUserIdToInboxBlacklist,
    removeAllUserIdFromInboxBlacklist
} = chatInboxSlice.actions;

export default chatInboxSlice.reducer;
