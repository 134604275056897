/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';

import rootReducer from './reducers';

import { createWrapper } from 'next-redux-wrapper';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import logger from 'redux-logger';

import { clearCache } from 'authentication/redux/slices/userSlice';
import { authApi } from 'authentication/redux/api/authApi';
import { applicantApi } from 'dashboard/applicant/redux/api/applicantApi';
import { workerApi } from 'dashboard/redux/api/workerApi';
import { jobOpeningESApi } from 'dashboard/jobOpening/redux/api/jobOpeningES';
import { coreApi } from 'core/redux/api/coreApi';
import { chatApi } from 'dashboard/chat/redux/api/chatApi';
import { apiErrorHandler } from './errorHandler';

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
    const middlewareArray = [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }),
        authApi.middleware,
        applicantApi.middleware,
        workerApi.middleware,
        coreApi.middleware,
        chatApi.middleware,
        jobOpeningESApi.middleware
    ];

    middlewareArray.push(apiErrorHandler);

    if (process.env.NODE_ENV === 'development') {
        middlewareArray.push(logger);
    }
    return middlewareArray;
};

let rehydrationComplete = (value: unknown): void => {
    console.log(`REHYDRATION [${value}]`);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rehydrationPromise = new Promise((resolve, _) => {
    rehydrationComplete = resolve;
});

export const rehydration = async (): Promise<any> => {
    return rehydrationPromise;
};

const useStore = () => {
    const isServer = typeof window === 'undefined';
    const store = configureStore({
        reducer: (rootState, action) => {
            let state = rootState;
            //  clear rtk caches
            if (clearCache.match(action)) {
                state = undefined;
            }
            return rootReducer(state, action);
        },
        middleware,
        devTools: process.env.NODE_ENV === 'development'
    }); // Creating the store again

    if (isServer) {
        return store;
    } else {
        // @ts-ignore
        store.__persistor = persistStore(store, null, () => {
            rehydrationComplete('COMPLETE');
        }); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

        return store;
    }
};

export type AppStore = ReturnType<typeof useStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const wrapper = createWrapper<AppStore>(useStore);

export default useStore;
