import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMPANY_REJECTION_REASON_KEY } from 'authentication/constant';
import { authApi } from 'authentication/redux/api/authApi';
import useTracking from 'core/hooks/useTracking';
import { onboardingApi } from 'dashboard/companyOnboarding/redux/api/onboardingApi';
import { TargetTooltipEnum } from 'dashboard/contexts/OnboardingContext';
import { toast } from 'react-toastify';

const track = useTracking('LOGIN PAGE');
type UserSliceState = {
    user: User;
    is_consent_terms_conditions: boolean | null;
    onboarding_stages?: CompanyOnboardingStage | null;
    has_job_application: boolean;
    is_company_rejected: boolean;
    token: string | null;
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {} as User,
        token: null,
        is_consent_terms_conditions: null,
        onboarding_stages: null,
        has_job_application: false,
        is_company_rejected: false
    } as UserSliceState,
    reducers: {
        removeUser: () => {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem(TargetTooltipEnum.tnc);
            return {
                token: null,
                user: {} as User,
                is_consent_terms_conditions: null,
                onboarding_stages: null,
                has_job_application: false,
                is_company_rejected: false
            };
        },
        /**
         * this clearChace reducer action was used to prevent different user login in same device bug
         * the bug was event the user logged in is different, but if we are not clear the cached, redux toolkit
         * still keep the old redux history data, and it's make new user get old data from the old user.
         */
        clearCache: (state: UserSliceState) => {
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;
                state.token = payload.token;
                state.is_consent_terms_conditions = payload.is_consent_terms_conditions;
                state.has_job_application = payload.has_job_application;

                track.identify(payload.user?.phone_number);
                track.setUser(payload.user, payload.company);

                track.event('Login', {
                    CompanyUserId: payload.user.phone_number,
                    CompanyeUserName: payload.user.full_name,
                    CompanyUserEmail: payload.user.email,
                    CompanyName: payload.company ? payload.company.name : null
                });

                if (payload.company) {
                    track.event('Company Data when Login', {
                        ...payload.company
                    });
                } else {
                    track.event('Company Data when Login not provided');
                }

                window.localStorage.setItem('token', payload.token);
                window.localStorage.setItem(
                    TargetTooltipEnum.tnc,
                    payload.is_consent_terms_conditions.toString()
                );

                toast.success(`Selamat datang, ${state.user.full_name}`, {
                    position: 'top-center',
                    theme: 'colored',
                    hideProgressBar: true
                });

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.googleLogin.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;
                state.token = payload.token;
                state.is_consent_terms_conditions = payload.is_consent_terms_conditions;
                state.has_job_application = payload.has_job_application;

                window.localStorage.setItem('token', payload.token);
                window.localStorage.setItem(
                    TargetTooltipEnum.tnc,
                    payload.is_consent_terms_conditions.toString()
                );

                if (payload.user?.phone_number) {
                    track.identify(payload.user?.phone_number);
                    track.setUser(payload.user, payload.company);
                }

                if (payload.company) {
                    track.event('Company Data when Login', {
                        ...payload.company
                    });
                } else {
                    track.event('Company Data when Login not provided');
                }

                toast.success(`Selamat datang, ${state.user.full_name}`, {
                    position: 'top-center',
                    theme: 'colored',
                    hideProgressBar: true
                });

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.addPhoneNumber.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;

                track.identify(payload.user?.phone_number);
                track.setUser(payload.user, payload.company);

                track.event('Login', {
                    CompanyUserId: payload.user.phone_number,
                    CompanyeUserName: payload.user.full_name,
                    CompanyUserEmail: payload.user.email,
                    CompanyName: payload.company ? payload.company.name : null
                });

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.consentTnC.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;
                state.is_consent_terms_conditions = payload.is_consent_terms_conditions;
                window.localStorage.setItem(
                    TargetTooltipEnum.tnc,
                    payload.is_consent_terms_conditions.toString()
                );
                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.registerUser.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;
                state.token = payload.token;
                state.is_consent_terms_conditions = payload.is_consent_terms_conditions;
                state.has_job_application = payload.has_job_application;

                track.identify(payload.user.phone_number);
                track.setUser(payload.user, payload.company);

                track.event('Login', {
                    CompanyUserId: payload.user.phone_number,
                    CompanyeUserName: payload.user.full_name,
                    CompanyUserEmail: payload.user.email,
                    CompanyName: payload.company.name
                });

                window.localStorage.setItem('token', payload.token);
                window.localStorage.setItem(
                    TargetTooltipEnum.tnc,
                    payload.is_consent_terms_conditions.toString()
                );

                toast.success(`Selamat datang, ${state.user.full_name}`, {
                    position: 'top-center',
                    theme: 'colored',
                    hideProgressBar: true
                });

                return state;
            }
        );
        builder.addMatcher(
            onboardingApi.endpoints.getOnboardingStages.matchFulfilled,
            (state, { payload }: PayloadAction<CompanyOnboardingStages>) => {
                state.onboarding_stages = payload.onboarding_stage;
                state.has_job_application = payload.has_job_application;

                if (payload.is_company_rejected) {
                    window.localStorage.setItem(
                        COMPANY_REJECTION_REASON_KEY,
                        payload.rejection_reason as string
                    );
                }

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.getCompanyUserProfile.matchFulfilled,
            (state, { payload }: PayloadAction<CompanyUserProfile>) => {
                state.user = payload.user;

                return state;
            }
        );
    }
});

export const { removeUser, clearCache } = userSlice.actions;

export default userSlice.reducer;
