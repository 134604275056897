import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetState } from 'dashboard/redux/utils';
import { FilterSliceState, JobOpeningOrderBy } from './filterJobOpeningSlices';

const mobileFilterJobOpeningSlice = createSlice({
    name: 'mobileFilterJobOpening',
    initialState: {
        page: 1,
        order_by: JobOpeningOrderBy.newest,
        job_category_id: [],
        status: [],
        city: [],
        new_status: []
    } as FilterSliceState,
    reducers: {
        setMobileJobCategory: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.job_category_id ?? [], 'job_category_id', payload);
        },
        setMobileStatus: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.status ?? [], 'status', payload);
        },
        setMobileStatuses: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, status: payload };
        },
        setMobileNewStatus: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.new_status ?? [], 'new_status', payload);
        },
        setMobileNewStatuses: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, new_status: payload };
        },
        setMobileCity: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.city ?? [], 'city', payload);
        },
        setMobileCities: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, city: payload };
        },
        setMobileOrderBy: (
            state: FilterSliceState,
            { payload }: PayloadAction<JobOpeningOrderBy>
        ) => {
            return {
                ...state,
                order_by: payload,
                page: 1
            };
        },
        resetMobileFilter: () => {
            return {
                order_by: JobOpeningOrderBy.newest,
                job_category_id: [],
                status: [],
                city: [],
                new_status: []
            };
        }
    }
});

export const {
    setMobileJobCategory,
    setMobileCity,
    setMobileStatus,
    setMobileOrderBy,
    setMobileCities,
    setMobileStatuses,
    setMobileNewStatus,
    setMobileNewStatuses,
    resetMobileFilter
} = mobileFilterJobOpeningSlice.actions;

export default mobileFilterJobOpeningSlice.reducer;
