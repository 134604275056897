import { combineReducers } from '@reduxjs/toolkit';
import filterJobOpeningReducer from './slices/filterJobOpeningSlices';
import mobileFilterJobOpeningReducer from './slices/mobileFilterJobOpeningSlices';
import { persistReducer } from 'redux-persist';
import storage from 'redux/_sync_storage';
import uiConfigurationReducer from './slices/uiConfigurationSlices';

const uiPersistConfig = {
    key: 'jobOpeningUI',
    storage,
    whitelist: ['view', 'showConfetti']
};

const jobOpeningReducer = combineReducers({
    /**
     * The reason behind why right now we are using two different filter reducer
     * on desktop and mobile was because the different filter flow requirement.
     *
     * on Desktop, every user click filter option, it will simultanously filtering the data,
     * but on mobile, user should pick filter option first, then if already done, user will
     * click "terapkan" button, when this button clicked, then filter will executed
     */
    filter: filterJobOpeningReducer,
    mobileFilter: mobileFilterJobOpeningReducer,
    uiConfiguration: persistReducer(uiPersistConfig, uiConfigurationReducer)
});

export default jobOpeningReducer;
