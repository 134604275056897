import { baseApi } from 'redux/api/baseApi';

const WORKER_BASE_URL = 'workers/';
const JOBS_BASE_URL = 'jobs/';

export const workerApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDegreeType: builder.query<DegreeTypeResponseData, void>({
            query: () => `${JOBS_BASE_URL}degree-types/`
        }),
        getPlacementType: builder.query<PlacementTypeResponseData, void>({
            query: () => `${JOBS_BASE_URL}placement-types/`
        }),
        getWorkerDegreeType: builder.query<DegreeTypeResponseData, void>({
            query: () => `${WORKER_BASE_URL}degree-types/`
        }),
        getJobCategoryType: builder.query<JobCategoriesResponseData, void>({
            query: () => `${WORKER_BASE_URL}public/job-categories/`
        }),
        getStaffingType: builder.query<StaffingTypeResponseData, void>({
            query: () => `${WORKER_BASE_URL}staffing-types/`
        })
    }),
    overrideExisting: false
});

export const {
    useGetDegreeTypeQuery,
    useGetPlacementTypeQuery,
    useGetJobCategoryTypeQuery,
    useGetStaffingTypeQuery,
    useGetWorkerDegreeTypeQuery
} = workerApi;
