import moment from 'moment';
import { cloneElement } from 'react';
import { uuid } from 'stanza/Utils';

export const isNotNullAndNotUndefined = <T>(objectToCheck: T): boolean => {
    return objectToCheck !== null && objectToCheck !== undefined;
};

export const getWebviewFlag = (): string => {
    const flag = typeof window !== 'undefined' ? window.localStorage.getItem('webview') : null;

    if (flag !== null) {
        return 'App';
    }

    return 'Web';
};

export const makeEllipsis = (text: string, limit = 50): string => {
    if (text?.length > limit) {
        return `${text?.substring(0, limit)}...`;
    }
    return text;
};

// Create our number formatter.
export const currencyFormatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const compactCurrencyFormatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    notation: 'compact'
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const addZeroBefore = (data: number): string => {
    return `${data < 10 ? '0' : ''}${data}`;
};

export const generateListOfComponents = (amount: number, component: JSX.Element): JSX.Element[] => {
    const res = [];
    for (let i = 0; i < amount; i += 1) {
        res.push(
            cloneElement(component, {
                key: uuid()
            })
        );
    }

    return res;
};

export const unicodeToChar = (text: string): string => {
    return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
};

export const dateFormatter = (date: string | Date) => {
    // Format date to day, date month year
    // e.g Senin, 2 Januari 2023
    return moment(date).format('dddd, DD MMMM YYYY');
};

export const timeFormatter = (date: string | Date) => {
    // Format date to hh:mm
    // e.g 12:00
    return moment(date).format('HH:mm');
};

export const standardizeDate = (date: string | Date) => {
    // format date to yyyy-mm-dd
    // e.g 2021-01-01
    return moment(date).format('YYYY-MM-DD');
};

export const bytesConverter = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const readAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target?.result as string);
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsDataURL(file);
    });
};

export const getATSVersion = (): string => {
    return `${process.env.NEXT_PUBLIC_NODE_ENV}-${(
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string
    ).substring(0, 4)}`;
};

/**
 * This function is used to format currency without white space after the currency symbol
 */
export const currency = (amount: number, compact?: boolean): string => {
    if (Number(amount) == 0) return 'Rp0';

    const res = decodeHtmlEntities(
        compact
            ? compactCurrencyFormatter.format(amount)
            : currencyFormatter.format(amount).replace(/\s/g, '')
    );

    // Remove decimals
    return compact ? res : res.substring(0, res.length - 3);
};

export const decodeHtmlEntities = (str: string): string => {
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
};

export const removeDotsFromValue = (value: string): string => {
    return value.replace(/\./g, '');
};

export const getRandomValuefromArray = (arr: any[]) => {
    const randomIndex = Math.floor(Math.random() * arr.length);

    return arr[randomIndex];
};
