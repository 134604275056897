import { combineReducers } from '@reduxjs/toolkit';
import userReducer from 'authentication/redux/slices/userSlice';
import companyReducer from 'authentication/redux/slices/companySlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux/_sync_storage';

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: [
        'user',
        'token',
        'is_consent_terms_conditions',
        'onboarding_stages',
        'has_job_application',
        'referral_code'
    ]
};

const companyPersistConfig = {
    key: 'company',
    storage,
    whitelist: ['id', 'logo_url', 'name']
};

const authenticationReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    company: persistReducer(companyPersistConfig, companyReducer)
});

export default authenticationReducer;
