import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from './config';

export const baseApi = createApi({
    tagTypes: [
        'Application',
        'Status',
        'JobOpening',
        'JobOpeningByApplicant',
        'LocationByApplicant',
        'WorkerChat',
        'JobOpeningPromoted',
        'GigWork',
        'GigWorkFulfillment',
        'LuminaCredit',
        'OnboardingStage',
        'CompanyDetail',
        'CompanyUser',
        'FAQ',
        'FeatureFlag',
        'SubscriptionDetail',
        'RecommendedWorker'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const token = (getState() as RootState).authentication.user.token;
            const rawToken = window.localStorage.getItem('token');

            headers.set(
                'Git-Commit-SHA',
                `lumina-ats:${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}`
            );
            headers.set(
                'Git-Pull-Request-ID',
                `lumina-ats:${process.env.NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID}`
            );

            if (endpoint === 'getCompanyIndustry') {
                return headers;
            }

            if (token || rawToken) {
                headers.set('Authorization', `Token ${token ?? rawToken}`);
            }

            return headers;
        }
    }),
    endpoints: () => ({})
});
