export enum DashboardRouteEnum {
    applicant = 'applicant',
    jobOpening = 'opening',
    gig = 'gig',
    profile = 'profile',
    chat = 'chat',
    voucher = 'voucher',
    faq = 'faq',
    premium = 'premium'
}

export enum JobPlacementType {
    onsite = 'onsite',
    hybrid = 'hybrid',
    remote = 'remote',
    special = 'special_placement'
}

export const DashboardFullRoute = {
    applicant: `/dashboard/${DashboardRouteEnum.applicant}`,
    jobOpening: `/dashboard/${DashboardRouteEnum.jobOpening}`,
    gig: `/dashboard/${DashboardRouteEnum.gig}`,
    chat: `/dashboard/${DashboardRouteEnum.chat}`,
    profile: `/dashboard/${DashboardRouteEnum.profile}`,
    voucher: `/dashboard/${DashboardRouteEnum.voucher}`,
    faq: `/dashboard/${DashboardRouteEnum.faq}`,
    premium: `/dashboard/${DashboardRouteEnum.premium}`
};

export const EmployeeTableHeader = [
    'Nama Pengguna',
    'Email Pengguna',
    'Nomor Telepon',
    'Tipe Akses',
    'Waktu Perubahan'
];
