import { CSSObject } from '@emotion/react';
import { luminaColorPallete } from 'styles/mui/components/Color';

export const luminaInput = {
    textInput: {
        padding: '3px 16px',
        borderRadius: '8px',
        fontSize: '16px',
        width: '100%',
        border: '1px solid transparent',
        '&:hover, &.Mui-focused, &:active': {
            borderColor: `${luminaColorPallete.sky[500]} !important`
        },
        '&.error': {
            borderColor: `${luminaColorPallete.fire[400]} !important`
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
        },
        'input[type="number"]': {
            MozAppearance: 'textfield'
        },
        input: {
            '&::placeholder': {
                color: `${luminaColorPallete.greyscale[500]} !important`,
                opacity: 1
            }
        }
        // backgroundColor: `${luminaColorPallete.greyscale[50]} !important`
    } as CSSObject
};
