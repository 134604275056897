import { isNotNullAndNotUndefined } from 'core/utils';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as XMPP from 'stanza';
import { setGlobalInboxCounter, setRoomInboxCounter } from '../redux/slices/chatInboxSlice';
import { setUserPresences, UserPresceneType } from '../redux/slices/userPresenceSlice';
import inboxPlugin from 'lib/stanza/inbox';
import smartMarkerPlugin from 'lib/stanza/smartMarker';
import { InboxResult } from 'lib/stanza/inbox/protocol';
import { useLoggedInUserQuery } from 'authentication/redux/api/authApi';
import { getToken } from 'authentication/redux/selectors/userSelector';
import useInbox from '../hooks/useInbox';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import useFeatureFlag from 'core/hooks/useFeatureFlag';
import { getChatServerUrl, getUserJID } from '../utils';
import { uuid } from 'stanza/Utils';
import { chatApi } from '../redux/api/chatApi';
import { getChatRoomFilterParams } from '../redux/selectors/chatRoomSelector';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import moment from 'moment';
import useCompanyUser from 'authentication/hooks/useCompanyUser';

const AUTH_RETRY_KEY = 'auth_retry';
const LAST_AUTH_RETRY_TIME = 'last_auth_retry_time';
const RETRY_MECHANISM_INTERVAL_HOUR = 1; // in hour

interface StanzaContextType {
    client?: XMPP.Agent;
    connectXMPPServer: (jid: string, password: string) => void;
    username?: JIDUsername;
    sessionStarted: boolean;
    pushChatToTheTop: (
        userId: string,
        draft: MaybeDrafted<ListResponseData<WorkerChatProfile>>
    ) => ListResponseData<WorkerChatProfile>;
}

const StanzaContext = createContext<StanzaContextType>({} as StanzaContextType);

export function StanzaProvider({ children }: { children: ReactNode }): JSX.Element {
    // will be treated as singleton
    const [client, setClient] = useState<XMPP.Agent>();
    const [isConnecting, setIsConnecting] = useState(false);
    const [username, setUsername] = useState<JIDUsername>();
    const [sessionStarted, setSessionStarted] = useState(false);
    const token = useSelector(getToken);
    const activeChatFilterState = useSelector(getChatRoomFilterParams);
    const { isACompanyUser, isAuthenticated } = useCompanyUser();
    const { data } = useLoggedInUserQuery(undefined, {
        skip: !isAuthenticated || !isACompanyUser
    });
    const dispatch = useDispatch();
    const featureFlag = useFeatureFlag();
    const { addNewInboxData, createNotificationBanner } = useInbox();
    const track = useLazyAuthTracking('CHAT');

    const connectXMPPServer = (userId: string, password: string, force?: boolean): void => {
        if (!isNotNullAndNotUndefined(client) || force) {
            const stanzaClient = XMPP.createClient({
                jid: getUserJID(userId),
                password: password,
                transports: {
                    websocket: `wss://${getChatServerUrl()}:5280/ws/`
                },
                resource: `lumina-ats-${uuid()}`,
                timeout: 30
            });

            setIsConnecting(true);
            stanzaClient.connect({
                autoReconnect: true
            });

            setClient(stanzaClient);
            setUsername(getUserJID(userId));
        }
    };

    const reauthenticateMechanism = (): void => {
        const retryCount = window.localStorage.getItem(AUTH_RETRY_KEY) ?? 0;

        if (Number(retryCount) < 3) {
            if (data) {
                toast.warn(`Menyambungkan... (Gagal Login Chat Server)`, {
                    position: 'top-center',
                    theme: 'colored',
                    toastId: 'reconnect-failure'
                });
                window.localStorage.setItem(AUTH_RETRY_KEY, `${Number(retryCount) + 1}`);
                window.localStorage.setItem(LAST_AUTH_RETRY_TIME, new Date().toString());
                connectXMPPServer(`${data.id}`, token, true);
            }
        } else {
            const last_auth_time = window.localStorage.getItem(LAST_AUTH_RETRY_TIME);

            if (
                last_auth_time !== null &&
                moment.duration(moment().diff(last_auth_time)).asHours() >=
                    RETRY_MECHANISM_INTERVAL_HOUR
            ) {
                window.localStorage.setItem(AUTH_RETRY_KEY, '0');
                window.localStorage.removeItem(LAST_AUTH_RETRY_TIME);
                reauthenticateMechanism();
            } else {
                toast.warn('Gagal Login Chat Server', {
                    position: 'top-center',
                    theme: 'colored',
                    toastId: 'reconnect-failure'
                });
                throw new Error(
                    'Fail to Login Chat Server [Triggered from Reauthenticate Mechanism]'
                );
            }
        }
    };

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            isNotNullAndNotUndefined(client) &&
            featureFlag?.enable_chat_feature &&
            sessionStarted
        ) {
            if (!('Notification' in window)) {
                track.event("Browser don't support Notification");
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted');
                    }
                });
            }
        }
    }, [window, client, featureFlag, sessionStarted]);

    useEffect(() => {
        if (data?.id && isNotNullAndNotUndefined(token) && featureFlag?.enable_chat_feature) {
            connectXMPPServer(`${data.id}`, token);
        }
    }, [data, token, featureFlag]);

    const getInbox = async (): Promise<void> => {
        try {
            const res = await client?.searchInbox(username as string, {
                hidden: true
            });

            dispatch(setGlobalInboxCounter(res?.inbox?.unread as number));
            res?.roomInbox?.forEach((inbox: InboxResult) =>
                dispatch(
                    setRoomInboxCounter({
                        id: inbox.from.split('@')[0] as string,
                        count: inbox.unread
                    })
                )
            );
        } catch (e) {
            track.event('Failed Load Inbox from XMPP Server');
        }
    };

    const pushChatToTheTop = (
        userId: string,
        draft: MaybeDrafted<ListResponseData<WorkerChatProfile>>
    ): ListResponseData<WorkerChatProfile> => {
        const targetData = draft.data.filter(
            (chat: WorkerChatProfile) => chat.user_id === userId
        )[0];
        const restData = draft.data.filter((chat: WorkerChatProfile) => chat.user_id !== userId);

        const sortedData = [targetData, ...restData];

        return {
            ...draft,
            data: sortedData
        };
    };

    useEffect(() => {
        if (isNotNullAndNotUndefined(client)) {
            client?.on('session:started', () => {
                client.getRoster().then(() => {
                    client.sendPresence({
                        priority: 5
                    });
                });
                setSessionStarted(true);
                client.use(inboxPlugin);
                client.use(smartMarkerPlugin);

                getInbox();
            });

            client?.on('connected', () => {
                setIsConnecting(false);
                client?.enableKeepAlive({
                    interval: 10,
                    timeout: 60
                });
            });

            client?.on('session:end', () => {
                setSessionStarted(false);
                client.sendPresence({
                    type: 'unavailable'
                });
            });

            client?.on('auth:failed', () => {
                track.event('Failed Login to XMPP Server', {
                    Event: 'auth:failed',
                    JID: username
                });

                client.disconnect();
                setSessionStarted(false);

                reauthenticateMechanism();
            });

            client?.on('disconnected', () => {
                if (!isConnecting) {
                    client.connect({
                        autoReconnect: true
                    });
                    setIsConnecting(true);
                    setSessionStarted(false);
                }
            });

            client?.on('subscribe', (presence) => {
                if (!presence.from.includes(username as string)) {
                    client.acceptSubscription(presence.from);
                }
            });

            client?.on('presence', (presence) => {
                if (!presence.from.includes(username as string)) {
                    presence;
                    dispatch(
                        setUserPresences({
                            id: presence.from.split('/')[0] as JIDUsername,
                            priority: presence.priority ?? 0,
                            type: (presence.type as UserPresceneType) ?? 'available',
                            show: presence.show
                        })
                    );
                }
            });

            client?.on('chat', (message) => {
                const userId = message.from.split('@')[0];
                if (isNotNullAndNotUndefined(message.body)) {
                    const needCreateBanner = addNewInboxData(userId, message.id as string);
                    if (needCreateBanner) {
                        createNotificationBanner(userId, message);
                        // Used to pop latest active chat to the most
                        dispatch(
                            chatApi.util.updateQueryData(
                                'getActiveChats',
                                activeChatFilterState,
                                (draft) => pushChatToTheTop(userId, draft)
                            )
                        );
                    }
                }
            });

            client?.on('--transport-disconnected', () => {
                track.event('Websocket Closed Disconnected', {
                    Event: '--transport-disconnected'
                });
                if (sessionStarted) {
                    window.location.reload();
                }
            });

            client?.on('stream:error', () => {
                track.event('Websocket Closed Error', {
                    Event: 'stream:error'
                });
                if (sessionStarted) {
                    window.location.reload();
                }
            });

            client?.sm.cache((state) => {
                sessionStorage.cachedSM = JSON.stringify(state);
            });
        }
    }, [client]);

    const memoedValue = useMemo(
        () => ({
            client,
            connectXMPPServer,
            username,
            sessionStarted,
            pushChatToTheTop
        }),
        [client, username, sessionStarted]
    );

    return <StanzaContext.Provider value={memoedValue}>{children}</StanzaContext.Provider>;
}

export const useStanza = (): StanzaContextType => {
    return useContext(StanzaContext);
};

export default StanzaContext;
