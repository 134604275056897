import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux/_sync_storage';
import uiConfigurationReducer from './slices/uiConfigurationSlices';
import gigWorkListReducer from './slices/gigWorkSlices';
import gigWorkerFulfillmentReducer from './slices/gigWorkerFulfillmentSlices';

const uiPersistConfig = {
    key: 'gigWorkUI',
    storage,
    whitelist: ['view']
};

const gigWorkReducer = combineReducers({
    uiConfiguration: persistReducer(uiPersistConfig, uiConfigurationReducer),
    gigWorkList: gigWorkListReducer,
    gigWorkerFulfillment: gigWorkerFulfillmentReducer
});

export default gigWorkReducer;
