export const luminaTypography = {
    '64-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '64px'
    },
    '64-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '64px'
    },
    '64-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '64px'
    },

    '48-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '48px'
    },
    '48-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '48px'
    },
    '48-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '48px'
    },

    '40-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '40px'
    },
    '40-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '40px'
    },
    '40-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px'
    },

    '36-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px'
    },
    '36-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '36px'
    },
    '36-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '36px'
    },

    '32-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px'
    },
    '32-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px'
    },
    '32-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '32px'
    },

    '24-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px'
    },
    '24-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px'
    },
    '24-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px'
    },

    '20-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    '20-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px'
    },
    '20-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px'
    },

    '16-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px'
    },
    '16-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px'
    },
    '16-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px'
    },

    '14-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    '14-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px'
    },
    '14-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px'
    },

    '12-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px'
    },
    '12-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px'
    },
    '12-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px'
    },

    '10-bold': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '10px'
    },
    '10-medium': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px'
    },
    '10-regular': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px'
    }
};

declare module '@mui/material/styles' {
    interface TypographyVariants {
        '64-bold': React.CSSProperties;
        '64-medium': React.CSSProperties;
        '64-regular': React.CSSProperties;

        '48-bold': React.CSSProperties;
        '48-medium': React.CSSProperties;
        '48-regular': React.CSSProperties;

        '40-bold': React.CSSProperties;
        '40-medium': React.CSSProperties;
        '40-regular': React.CSSProperties;

        '36-bold': React.CSSProperties;
        '36-medium': React.CSSProperties;
        '36-regular': React.CSSProperties;

        '32-bold': React.CSSProperties;
        '32-medium': React.CSSProperties;
        '32-regular': React.CSSProperties;

        '24-bold': React.CSSProperties;
        '24-medium': React.CSSProperties;
        '24-regular': React.CSSProperties;

        '20-bold': React.CSSProperties;
        '20-medium': React.CSSProperties;
        '20-regular': React.CSSProperties;

        '16-bold': React.CSSProperties;
        '16-medium': React.CSSProperties;
        '16-regular': React.CSSProperties;

        '14-bold': React.CSSProperties;
        '14-medium': React.CSSProperties;
        '14-regular': React.CSSProperties;

        '12-bold': React.CSSProperties;
        '12-medium': React.CSSProperties;
        '12-regular': React.CSSProperties;

        '10-bold': React.CSSProperties;
        '10-medium': React.CSSProperties;
        '10-regular': React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        '64-bold': React.CSSProperties;
        '64-medium': React.CSSProperties;
        '64-regular': React.CSSProperties;

        '48-bold': React.CSSProperties;
        '48-medium': React.CSSProperties;
        '48-regular': React.CSSProperties;

        '40-bold': React.CSSProperties;
        '40-medium': React.CSSProperties;
        '40-regular': React.CSSProperties;

        '36-bold': React.CSSProperties;
        '36-medium': React.CSSProperties;
        '36-regular': React.CSSProperties;

        '32-bold': React.CSSProperties;
        '32-medium': React.CSSProperties;
        '32-regular': React.CSSProperties;

        '24-bold': React.CSSProperties;
        '24-medium': React.CSSProperties;
        '24-regular': React.CSSProperties;

        '20-bold': React.CSSProperties;
        '20-medium': React.CSSProperties;
        '20-regular': React.CSSProperties;

        '16-bold': React.CSSProperties;
        '16-medium': React.CSSProperties;
        '16-regular': React.CSSProperties;

        '14-bold': React.CSSProperties;
        '14-medium': React.CSSProperties;
        '14-regular': React.CSSProperties;

        '12-bold': React.CSSProperties;
        '12-medium': React.CSSProperties;
        '12-regular': React.CSSProperties;

        '10-bold': React.CSSProperties;
        '10-medium': React.CSSProperties;
        '10-regular': React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        '64-bold': true;
        '64-medium': true;
        '64-regular': true;

        '48-bold': true;
        '48-medium': true;
        '48-regular': true;

        '40-bold': true;
        '40-medium': true;
        '40-regular': true;

        '36-bold': true;
        '36-medium': true;
        '36-regular': true;

        '32-bold': true;
        '32-medium': true;
        '32-regular': true;

        '24-bold': true;
        '24-medium': true;
        '24-regular': true;

        '20-bold': true;
        '20-medium': true;
        '20-regular': true;

        '16-bold': true;
        '16-medium': true;
        '16-regular': true;

        '14-bold': true;
        '14-medium': true;
        '14-regular': true;

        '12-bold': true;
        '12-medium': true;
        '12-regular': true;

        '10-bold': true;
        '10-medium': true;
        '10-regular': true;
    }
}
