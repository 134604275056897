import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'authentication/redux/api/authApi';
import { onboardingApi } from 'dashboard/companyOnboarding/redux/api/onboardingApi';

type CompanySliceState = {
    id: string | null;
    name: string | null;
    logo_url: string | null;
};

const companySlice = createSlice({
    name: 'company',
    initialState: {
        id: null,
        name: null,
        logo_url: null
    } as CompanySliceState,
    reducers: {
        removeCompany: () => {
            return { id: null, name: null, logo_url: null } as CompanySliceState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                if (payload.company) {
                    state.id = payload.company.id;
                    state.name = payload.company.name;
                    state.logo_url = payload.company.logo_url;
                }

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.googleLogin.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                if (payload.company) {
                    state.id = payload.company.id;
                    state.name = payload.company.name;
                    state.logo_url = payload.company.logo_url;
                }

                return state;
            }
        );
        builder.addMatcher(
            onboardingApi.endpoints.createCompany.matchFulfilled,
            (state, { payload }: PayloadAction<Company>) => {
                state.id = payload.company_id as string;
                state.name = payload.name;
                state.logo_url = payload.logo_url;

                return state;
            }
        );
        builder.addMatcher(
            authApi.endpoints.getCompanyUserProfile.matchFulfilled,
            (state, { payload }: PayloadAction<CompanyUserProfile>) => {
                return { ...state, ...payload.company };
            }
        );
    }
});

export const { removeCompany } = companySlice.actions;

export default companySlice.reducer;
