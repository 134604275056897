import { useEffect, useState } from 'react';

type WindowSize = {
    width: number;
    height: number;
};

const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        /**
         * This 1025 value came from the based breakpoint we use on desktop which is >= 1024 px.
         */
        width: 1025,
        height: 1025
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize(): void {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
};

export default useWindowSize;
