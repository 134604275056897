import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GigWorkerFulfillmentSliceState = BaseListQueryParams & {
    fulfillment_status: string[];
    job_category_id: string[];
};

const gigWorkerFulfillmentSlice = createSlice({
    name: 'gigWorkerFulfillment',
    initialState: {
        page: 1
    } as GigWorkerFulfillmentSliceState,
    reducers: {
        changePage: (state: GigWorkerFulfillmentSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        }
    }
});

export const { changePage } = gigWorkerFulfillmentSlice.actions;

export default gigWorkerFulfillmentSlice.reducer;
