import useCompanyUser from 'authentication/hooks/useCompanyUser';
import { useGetFeatureFlagQuery } from 'core/redux/api/coreApi';

const useFeatureFlag = (): ATSFeatureFlag => {
    const { isACompanyUser, isAuthenticated } = useCompanyUser();
    const { data } = useGetFeatureFlagQuery(undefined, {
        skip: !isAuthenticated || !isACompanyUser
    });

    return { ...data } as ATSFeatureFlag;
};

export default useFeatureFlag;
