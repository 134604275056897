import { isNotNullAndNotUndefined } from 'core/utils';

export const currentUser = (state: RootState): User => state.authentication.user.user as User;
export const getConsentTnC = (state: RootState): boolean | null =>
    state.authentication.user.is_consent_terms_conditions;
export const getToken = (state: RootState): string => state.authentication.user.token as string;
export const getIsHasApplication = (state: RootState): boolean =>
    state.authentication.user.has_job_application;
export const getCompanyUserOnboardingStages = (state: RootState): CompanyOnboardingStage | null =>
    state.authentication.user.onboarding_stages ?? 'has_signed_up';
export const getIsUserPhoneNumberExist = (state: RootState): boolean => {
    if (
        isNotNullAndNotUndefined(state.authentication.user.user.phone_number) &&
        state.authentication.user.user.phone_number.length > 0
    )
        return true;

    return false;
};
