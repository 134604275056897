import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FilterSliceState = Omit<Required<RecommendedWorkerQueryParams>, 'limit'> & {
    recommendedWorkerOnDetail: string | null;
};

const filterRecommendedWorkerSlice = createSlice({
    name: 'filterRecommendedWorker',
    initialState: {
        page: 1,
        recommendedWorkerOnDetail: '',
        is_unlocked: ''
    } as FilterSliceState,
    reducers: {
        changePage: (state: FilterSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        },
        setRecommendedWorkerOnDetail: (
            state: FilterSliceState,
            { payload }: PayloadAction<string | null>
        ) => {
            return {
                ...state,
                recommendedWorkerOnDetail: payload
            };
        },
        setFilterUnlock: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                is_unlocked: payload
            };
        }
    }
});

export const { changePage, setRecommendedWorkerOnDetail, setFilterUnlock } =
    filterRecommendedWorkerSlice.actions;

export default filterRecommendedWorkerSlice.reducer;
