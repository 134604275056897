import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    checkIsMultipleStatusSelected,
    isObjectAlreadyOnArrayById
} from 'dashboard/applicant/utils/application';
import { toast } from 'react-toastify';
import { applicantApi } from '../api/applicantApi';

type SelectedSliceState = {
    isAllSelected: boolean;
    selectedApplicants: ApplicationWithJustExpiry[]; // list of application id
    applicantOnDetail: string | null;
    isMultipleStatusSelected: boolean;
};

export enum ApplicationStatusEnum {
    new = 'new',
    in_progress = 'in_progress',
    rejected = 'rejected',
    withdraw = 'withdraw',
    hired = 'hired'
}

const selectApplicantSlice = createSlice({
    name: 'selectApplicant',
    initialState: {
        isAllSelected: false,
        selectedApplicants: [],
        applicantOnDetail: null,
        isMultipleStatusSelected: false
    } as SelectedSliceState,
    reducers: {
        selectApplicant: (
            state: SelectedSliceState,
            { payload }: PayloadAction<ApplicationWithJustExpiry>
        ) => {
            let newSelectedApplicants = state.selectedApplicants;

            if (
                !isObjectAlreadyOnArrayById<ApplicationWithJustExpiry>(
                    payload.id as string,
                    newSelectedApplicants
                )
            ) {
                newSelectedApplicants = [...newSelectedApplicants, payload];
            }

            return {
                ...state,
                selectedApplicants: newSelectedApplicants,
                isMultipleStatusSelected: checkIsMultipleStatusSelected(newSelectedApplicants)
            };
        },
        removeApplicant: (
            state: SelectedSliceState,
            { payload }: PayloadAction<ApplicationWithJustExpiry>
        ) => {
            const newSelectedApplicants = state.selectedApplicants.filter(
                (application: ApplicationWithJustExpiry) => application.id !== payload.id
            );
            return {
                ...state,
                selectedApplicants: newSelectedApplicants,
                isMultipleStatusSelected: checkIsMultipleStatusSelected(newSelectedApplicants)
            };
        },
        setApplicantOnDetail: (
            state: SelectedSliceState,
            { payload }: PayloadAction<string | null>
        ) => {
            return {
                ...state,
                applicantOnDetail: payload
            };
        },
        selectAllApplicant: (
            state: SelectedSliceState,
            { payload }: PayloadAction<ApplicationWithJustExpiry[]>
        ) => {
            return {
                ...state,
                isAllSelected: true,
                selectedApplicants: payload,
                isMultipleStatusSelected: checkIsMultipleStatusSelected(payload)
            };
        },
        removeAllApplicant: (state: SelectedSliceState) => {
            return {
                ...state,
                isAllSelected: false,
                selectedApplicants: [],
                isMultipleStatusSelected: checkIsMultipleStatusSelected([])
            };
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            applicantApi.endpoints.updateApplicationStatus.matchFulfilled,
            (state) => {
                toast.success('Status kandidat berhasil diubah.', {
                    position: 'top-center',
                    theme: 'colored',
                    hideProgressBar: true
                });

                return {
                    ...state,
                    isAllSelected: false,
                    selectedApplicants: [],
                    isMultipleStatusSelected: false
                };
            }
        );
    }
});

export const {
    selectAllApplicant,
    removeAllApplicant,
    selectApplicant,
    removeApplicant,
    setApplicantOnDetail
} = selectApplicantSlice.actions;

export default selectApplicantSlice.reducer;
