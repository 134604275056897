import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetState } from 'dashboard/redux/utils';
import mixpanel from 'mixpanel-browser';

export enum JobOpeningOrderBy {
    recommended = 'recommended',
    newest = 'newest',
    oldest = 'oldest',
    alphabetical_asc = 'alphabetical_asc',
    expiry = 'expiry_date_asc'
}

export const JobOpeningLabel = {
    recommended: 'Sesuai rekomendasi',
    newest: 'Paling baru',
    oldest: 'Paling lama',
    expiry_date_asc: 'Kedaluwarsa Terdekat'
};

export type FilterSliceState = JobOpeningQueryParams;

const filterJobOpeningSlice = createSlice({
    name: 'filterJobOpening',
    initialState: {
        page: 1,
        order_by: JobOpeningOrderBy.newest,
        job_category_id: [],
        status: [],
        city: [],
        placement_type: [],
        search_query: '',
        new_status: []
    } as FilterSliceState,
    reducers: {
        setJobCategory: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.job_category_id ?? [], 'job_category_id', payload);
        },
        setPlacementType: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.placement_type ?? [], 'placement_type', payload);
        },
        setStatus: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.status ?? [], 'status', payload);
        },
        setStatuses: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, status: payload };
        },
        setNewStatus: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.new_status ?? [], 'new_status', payload);
        },
        setNewStatuses: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, new_status: payload };
        },
        setCity: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.city ?? [], 'city', payload);
        },
        setCities: (state: FilterSliceState, { payload }: PayloadAction<string[]>) => {
            return { ...state, city: payload };
        },
        setSearchQuery: (state: FilterSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                page: 1,
                search_query: payload
            };
        },
        changePage: (state: FilterSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        },
        setOrderBy: (state: FilterSliceState, { payload }: PayloadAction<JobOpeningOrderBy>) => {
            return {
                ...state,
                order_by: payload,
                page: 1
            };
        },
        setFilterFromMobile: (
            state: FilterSliceState,
            { payload }: PayloadAction<JobOpeningQueryParams>
        ) => {
            return {
                ...state,
                ...payload
            };
        },
        resetFilter: () => {
            mixpanel.track('Resets Job Opening List Filter', { Tag: 'JOB OPENING' });
            return {
                page: 1,
                order_by: JobOpeningOrderBy.newest,
                job_category_id: [],
                status: [],
                city: [],
                placement_type: [],
                search_query: '',
                new_status: []
            };
        }
    }
});

export const {
    setJobCategory,
    setCity,
    setStatus,
    setSearchQuery,
    changePage,
    setOrderBy,
    resetFilter,
    setFilterFromMobile,
    setStatuses,
    setCities,
    setNewStatus,
    setNewStatuses,
    setPlacementType
} = filterJobOpeningSlice.actions;

export default filterJobOpeningSlice.reducer;
