import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GigWorkUIConfigurationState = {
    view: null | 'list' | 'grid';
};

const uiConfigurationSlices = createSlice({
    name: 'gigWorkUIConfoguration',
    initialState: {
        view: 'list'
    } as GigWorkUIConfigurationState,
    reducers: {
        setViewConfig: (
            state: GigWorkUIConfigurationState,
            { payload }: PayloadAction<null | 'list' | 'grid'>
        ) => {
            return { ...state, view: payload };
        }
    }
});

export const { setViewConfig } = uiConfigurationSlices.actions;

export default uiConfigurationSlices.reducer;
