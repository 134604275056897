import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetState } from 'dashboard/redux/utils';
import { ApplicantOrderBy } from './filterApplicantSlices';

export type MobilefilterSliceState = {
    order_by: ApplicantOrderBy;
    degree: string[];
    city: string[];
    gender: Gender[];
    age_min: number;
    age_max: number;
    job_category: string[];
    score_assessment: string[];
};

const mobileFilterApplicantSlice = createSlice({
    name: 'mobileFilterApplicant',
    initialState: {
        order_by: ApplicantOrderBy.default_sort_by,
        degree: [],
        city: [],
        gender: [],
        age_min: 0,
        age_max: 0,
        job_category: [],
        score_assessment: []
    } as MobilefilterSliceState,
    reducers: {
        setMobileDegree: (state: MobilefilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.degree, 'degree', payload);
        },
        setMobileGender: (state: MobilefilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.gender, 'gender', payload);
        },

        setMobileCity: (state: MobilefilterSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.city, 'city', payload);
        },
        setMobileJobCategory: (
            state: MobilefilterSliceState,
            { payload }: PayloadAction<string>
        ) => {
            return commonSetState(state, state.job_category, 'job_category', payload);
        },
        setMobileScoreAssessment: (
            state: MobilefilterSliceState,
            { payload }: PayloadAction<string>
        ) => {
            return commonSetState(state, state.score_assessment, 'score_assessment', payload);
        },
        setMobileOrderBy: (
            state: MobilefilterSliceState,
            { payload }: PayloadAction<ApplicantOrderBy>
        ) => {
            return {
                ...state,
                order_by: payload,
                page: 1
            };
        },
        resetMobileFilter: () => {
            return {
                order_by: ApplicantOrderBy.default_sort_by,
                job_opening_id: [],
                status: [],
                degree: [],
                city: [],
                gender: [],
                age_min: 0,
                age_max: 0,
                job_category: [],
                score_assessment: []
            };
        }
    }
});

export const {
    setMobileCity,
    setMobileDegree,
    setMobileGender,
    setMobileJobCategory,
    setMobileScoreAssessment,
    setMobileOrderBy,
    resetMobileFilter
} = mobileFilterApplicantSlice.actions;

export default mobileFilterApplicantSlice.reducer;
