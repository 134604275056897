import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { standardizeDate } from 'core/utils';
import { commonSetState } from 'dashboard/redux/utils';
import moment from 'moment';

export type FilterLuminaCreditSliceState = Omit<Required<CompanyTransactionHistoryParams>, 'limit'>;

const filterLuminaCreditSlice = createSlice({
    name: 'filterLuminaCredit',
    initialState: {
        category: [],
        page: 1,
        end_datetime: '',
        start_datetime: ''
    } as FilterLuminaCreditSliceState,
    reducers: {
        setCategory: (state: FilterLuminaCreditSliceState, { payload }: PayloadAction<string>) => {
            return commonSetState(state, state.category, 'category', payload);
        },
        setDate: (state: FilterLuminaCreditSliceState, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                start_datetime: `${moment(payload)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD')}T17:00:00`,
                end_datetime: `${payload}T17:00:00`,
                page: 1
            };
        },
        setAllDate: (state: FilterLuminaCreditSliceState) => {
            return {
                ...state,
                start_datetime: '',
                end_datetime: '',
                page: 1
            };
        },
        setToday: (state: FilterLuminaCreditSliceState) => {
            return {
                ...state,
                start_datetime: `${moment().subtract(1, 'day').format('YYYY-MM-DD')}T17:00:00`,
                end_datetime: `${standardizeDate(new Date())}T17:00:00`,
                page: 1
            };
        },
        setLatestWeek: (state: FilterLuminaCreditSliceState) => {
            const today = new Date();
            const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return {
                ...state,
                start_datetime: `${standardizeDate(lastWeek)}T17:00:00`,
                end_datetime: `${standardizeDate(
                    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
                )}T17:00:00`,
                page: 1
            };
        },
        changePage: (state: FilterLuminaCreditSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        },
        resetPage: (state: FilterLuminaCreditSliceState) => {
            return {
                ...state,
                page: 1
            };
        }
    }
});

export const { setCategory, setDate, setAllDate, setToday, setLatestWeek, changePage, resetPage } =
    filterLuminaCreditSlice.actions;

export default filterLuminaCreditSlice.reducer;
