export const STATUS_TRANSFORM: LuminaObject = {
    new: 'Baru',
    read: 'Dilihat',
    in_review: 'Review',
    interview: 'Interview',
    rejected: 'Ditolak',
    hired: 'Diterima',
    withdraw: 'Dibatalkan'
};

export const STATUS_FSM: LuminaObject = {
    new: {
        options: ['new', 'rejected']
    },
    read: {
        options: ['read', 'in_review', 'rejected']
    },
    in_review: {
        options: ['in_review', 'interview', 'rejected']
    },
    interview: {
        options: ['interview', 'hired', 'rejected']
    },
    rejected: {
        options: ['rejected']
    },
    hired: {
        options: ['hired']
    }
};

export const STATUS_ADVANCE: LuminaObject = {
    new: {
        target_status: 'in_review',
        action_text: 'Pindahkan ke review'
    },
    read: {
        target_status: 'in_review',
        action_text: 'Pindahkan ke review'
    },
    in_review: {
        target_status: 'interview',
        action_text: 'Pindahkan ke interview'
    },
    interview: {
        target_status: 'hired',
        action_text: 'Terima kandidat'
    }
};

export const BLURRED_WORKER_PROFILE_URL = [
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-1.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-2.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-3.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-4.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-5.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-6.png',
    'https://djhutyrz7bno6.cloudfront.net/assets/ats/reco-worker-blurred-7.png'
];

export const RECOMMENDED_WORKER_DEFAULT_PRICING_PLAN = '50 profil unlock';
