import { combineReducers } from '@reduxjs/toolkit';
import miniChatReducer from './slices/miniChatSlice';
import chatRoomReducer from './slices/chatRoomSlice';
import userPresenceReducer from './slices/userPresenceSlice';
import chatInboxReducer from './slices/chatInboxSlice';

const chatReducer = combineReducers({
    miniChat: miniChatReducer,
    chatRoom: chatRoomReducer,
    userPresence: userPresenceReducer,
    chatInbox: chatInboxReducer
});

export default chatReducer;
