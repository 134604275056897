import { baseApi } from 'redux/api/baseApi';

const AUTH_BASE_URL = 'users/company/';
const COMPANY_BASE_URL = 'companies/profile/';
const PAYMENT_BASE_URL = 'payments/companies/';

export const profileApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        changePassword: builder.mutation<LoginResponseData, ChangePasswordInputData>({
            query: (data: ChangePasswordInputData) => ({
                url: `${AUTH_BASE_URL}change-password/`,
                method: 'POST',
                body: data
            })
        }),
        getCompanyDetail: builder.query<FullCompany, void>({
            query: () => `${COMPANY_BASE_URL}`,
            providesTags: ['CompanyDetail']
        }),
        updateCompanyProfile: builder.mutation<CompanyDocument, CompanyDocumentParams>({
            query: (data: CompanyDocumentParams) => ({
                url: `/companies/${data.company_id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: ['CompanyDetail']
        }),
        getSubscriptionDetail: builder.query<SubscriptionDetail, void>({
            query: () => `${PAYMENT_BASE_URL}subscription/`,
            providesTags: ['SubscriptionDetail']
        }),
        getPaymentNotice: builder.query<PaymentNotice, void>({
            query: () => `${PAYMENT_BASE_URL}notice/`,
            providesTags: ['SubscriptionDetail']
        }),
        acknowledgePaymentNotice: builder.mutation<PaymentNotice, AcknowledgePaymentNoticeData>({
            query: (data: AcknowledgePaymentNoticeData) => ({
                url: `${PAYMENT_BASE_URL}notice/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['SubscriptionDetail']
        })
    })
});

export const {
    useChangePasswordMutation,
    useGetCompanyDetailQuery,
    useUpdateCompanyProfileMutation,
    useGetSubscriptionDetailQuery,
    useGetPaymentNoticeQuery,
    useAcknowledgePaymentNoticeMutation
} = profileApi;
