import { ChatRoomData, ChatSource, PendingMessage } from '../slices/chatRoomSlice';

export const getSelectedChatRoomUserId = (state: RootState): string | undefined =>
    state.dashboard.chat.chatRoom.selectedChat as string;

export const getSelectedChatRoomSource = (state: RootState): ChatSource | undefined =>
    state.dashboard.chat.chatRoom.selectedChatSource as ChatSource;

export const getSearchQuery = (state: RootState): string =>
    state.dashboard.chat.chatRoom.search_query as string;

export const getChatRoomFilterParams = (state: RootState): ActiveChatQueryParams => {
    const chatRoomState = state.dashboard.chat.chatRoom;

    const { search_query, page } = chatRoomState;

    return { search_query, page };
};

export const getChatRoomData = (state: RootState, user_id: string): ChatRoomData | undefined => {
    return state.dashboard.chat.chatRoom.room_data[user_id];
};

export const getChatRoomPendingMessage = (state: RootState, user_id: string): PendingMessage[] => {
    return state.dashboard.chat.chatRoom.room_data[user_id]?.pending_messages ?? [];
};

export const getIsChatRoomSelected =
    (state: RootState): ((id: string) => boolean) =>
    (id: string) =>
        (state.dashboard.chat.chatRoom.selectedChat as string) === id;

export const getLatestMessageAt = (state: RootState): Date | undefined => {
    return state.dashboard.chat.chatRoom.latest_message_at;
};

export const getFirstMessageAt = (state: RootState): Date | undefined => {
    return state.dashboard.chat.chatRoom.first_message_at;
};
