// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DefinitionOptions, attribute, createElement } from 'stanza/jxt';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function multipleMarker(namespace, element) {
    return {
        importer(xml: { getChildren: (arg0: any, arg1: any) => any; getNamespace: () => any }) {
            const result = [];
            const children = xml.getChildren(element, namespace || xml.getNamespace());
            for (const child of children) {
                const childId = child.getAttribute('id');
                const childFrom = child.getAttribute('from');
                const childType = child.getAttribute('type');
                const childTimestamp = child.getAttribute('timestamp');
                result.push({
                    id: childId,
                    from: childFrom,
                    type: childType,
                    timestamp: childTimestamp
                });
            }
            return result;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        exporter(xml, values, context) {
            for (const value of values) {
                const child = createElement(
                    namespace || xml.getNamespace(),
                    element,
                    context.namespace,
                    xml
                );
                child.setAttribute('id', value.id);
                child.setAttribute('from', value.from);
                child.setAttribute('type', value.type);
                child.setAttribute('timestamp', value.timestamp);
                xml.appendChild(child);
            }
        }
    };
}

declare module 'stanza' {
    export interface Message {
        smartMarker?: SmartMarkerFin;
    }

    export interface IQPayload {
        smartMarker?: SmartMarkerFin;
    }
}

export interface SmartMarkerQuery {
    type?: 'query';
    queryId?: string;
    peer: string;
}

export interface InboxAll {
    active: number;
    count: number;
    unread: number;
}

export interface SmartMarkerFin {
    type: 'result';
    smartMarker: SmartMarkersResult;
}

export interface SmartMarker {
    id: string;
    from: string;
    timestamp: string;
    type: ReadStatus;
}

export interface SmartMarkersResult {
    peer: string;
    markers: SmartMarker[];
}

const Protocol: DefinitionOptions[] = [
    {
        defaultType: 'query',
        defaultVersion: '2',
        element: 'query',
        fields: {
            peer: attribute('peer')
        },
        namespace: 'esl:xmpp:smart-markers:0',
        path: 'iq.smartMarker',
        type: 'query',
        typeField: 'type',
        version: '2',
        versionField: 'version'
    },
    {
        element: 'query',
        fields: {
            peer: attribute('peer'),
            markers: multipleMarker('esl:xmpp:smart-markers:0', 'marker')
        },
        namespace: 'esl:xmpp:smart-markers:0',
        path: 'iq.smartMarker',
        type: 'result',
        version: '2'
    }
];

export default Protocol;
