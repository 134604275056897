import { baseApi } from 'redux/api/baseApi';

export const onboardingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyIndustry: builder.query<CompanyIndustryResponseData, void>({
            query: () => ({
                url: 'companies/public/industries/'
            })
        }),
        getGroupedCompanyIndustry: builder.query<CompanyIndustryResponseData[], void>({
            query: () => ({
                url: 'companies/industries/grouped/'
            })
        }),
        createCompany: builder.mutation<Company, CompanyOnboardingInputData>({
            query: (data: CompanyOnboardingInputData) => ({
                url: 'companies/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['OnboardingStage', 'CompanyUser']
        }),
        joinCompany: builder.mutation<JoinCompanyResponseData, JoinCompanyInputData>({
            query: (data: JoinCompanyInputData) => ({
                url: 'companies/join/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['OnboardingStage', 'CompanyUser']
        }),
        getOnboardingStages: builder.query<CompanyOnboardingStages, void>({
            query: () => ({
                url: 'companies/onboarding-stages/'
            }),
            providesTags: ['OnboardingStage']
        }),
        getCompanyName: builder.query<ResponseData<CompanyNameResponseData>, CompanyNameQuery>({
            query: (data: CompanyNameQuery) => ({
                url: `companies/search/`,
                params: data
            })
        }),
        getPublicCompanyDetail: builder.query<FullPublicCompany, string>({
            query: (companyId: string) => `companies/public/${companyId}/profile/`
        })
    })
});

export const {
    useGetCompanyIndustryQuery,
    useGetGroupedCompanyIndustryQuery,
    useCreateCompanyMutation,
    useGetOnboardingStagesQuery,
    useGetCompanyNameQuery,
    useGetPublicCompanyDetailQuery,
    useJoinCompanyMutation
} = onboardingApi;
