import moment from 'moment';
import { STATUS_TRANSFORM } from '../constant';

const CONSTANT_DAY_COPY = {
    thisDay: '1 hari lagi',
    today: '1 hari',
    expired: 'Kedaluwarsa'
};

export const countTheDay = (time: string): number => {
    const targetTime = moment(time);
    const now = moment();
    const countByDay = targetTime.diff(now, 'days');

    return countByDay;
};

export const counterDays = (time: string, isExpired?: boolean): string => {
    const countByDay = countTheDay(time);

    if (isExpired) {
        return CONSTANT_DAY_COPY.expired;
    }

    if (countByDay > 0) {
        return `${countByDay} hari lagi`;
    }

    return CONSTANT_DAY_COPY.thisDay;
};

export const counterDaysV2 = (
    time: string,
    isExpired: boolean,
    forceCountdown?: boolean
): string => {
    const countByDay = countTheDay(time);
    if (isExpired) return CONSTANT_DAY_COPY.expired;
    if ((countByDay > 0 && countByDay <= 7) || (countByDay > 0 && forceCountdown))
        return `${countByDay} hari`;
    if (countByDay <= 1) return CONSTANT_DAY_COPY.today;
    return '';
};

export const isObjectAlreadyOnArrayById = <T extends LuminaObjectWithId>(
    id: string,
    data: T[]
): boolean => {
    return data.filter((element: T) => element.id === id).length > 0;
};

export const checkIsApplicationWithExpiredIncluded = (
    data: ApplicationWithJustExpiry[]
): boolean => {
    return data.filter((element: ApplicationWithJustExpiry) => element.is_expired).length > 0;
};

export const checkIsMultipleStatusSelected = (data: ApplicationWithJustExpiry[]): boolean => {
    if (data.length === 0) {
        return false;
    }

    const statuses: string[] = [];

    data.map((applicant: ApplicationWithJustExpiry) => {
        if (statuses.indexOf(applicant.status) === -1) {
            statuses.push(applicant.status);
        }
    });

    return statuses.length > 1;
};

export const calculateAllowedOptionsMessage = (
    currentValue: string,
    allowedOptions: string[]
): string => {
    const cleanedAllowedOption = allowedOptions.filter((value: string) => value !== currentValue);

    if (cleanedAllowedOption.length === 0) {
        return `Status lamaran sudah tidak bisa diubah karena sudah berstatus ${STATUS_TRANSFORM[currentValue]}`;
    }

    if (cleanedAllowedOption.length === 1) {
        return `Status ${STATUS_TRANSFORM[currentValue]} hanya bisa diubah menjadi ${
            STATUS_TRANSFORM[cleanedAllowedOption[0]]
        }`;
    }

    const statusMessage = cleanedAllowedOption.reduce((prev, curr) => {
        if (cleanedAllowedOption.indexOf(curr) === cleanedAllowedOption.length - 1) {
            return `${prev} atau ${STATUS_TRANSFORM[curr]}.`;
        }

        return `${prev} ${STATUS_TRANSFORM[curr]}`;
    }, '');

    const message = `Status ${STATUS_TRANSFORM[currentValue]} hanya bisa diubah menjadi status ${statusMessage}`;
    return message;
};
