export const getGlobalInboxCounter = (state: RootState): number =>
    state.dashboard.chat.chatInbox.global;

export const getRoomInboxCounter = (state: RootState, id: string): number | undefined =>
    state.dashboard.chat.chatInbox.room[id];

export const getUnreadRoomInboxCounter = (state: RootState): number => {
    const keys = Object.keys(state.dashboard.chat.chatInbox.room);

    let counter = 0;

    keys.forEach((key: string) => {
        if (state.dashboard.chat.chatInbox.room[key] > 0) {
            counter += 1;
        }
    });

    return counter;
};

export const getAllRoomInboxCounter = (state: RootState): { [id: string]: number } =>
    state.dashboard.chat.chatInbox.room;
