export const luminaColorPallete = {
    neutral: {
        white: '#fff',
        black: '#000'
    },
    greyscale: {
        50: '#F7F8F9',
        100: '#E5E6E8',
        200: '#CBCDD1',
        300: '#B0B4B9',
        400: '#969BA2',
        500: '#7C828B',
        600: '#63686F',
        700: '#4A4E53',
        800: '#323438',
        900: '#191A1C'
    },
    fire: {
        50: '#FFEBEA',
        100: '#FFBFBB',
        200: '#FF938B',
        300: '#FF675C',
        400: '#FF675C',
        500: '#DD281B',
        600: '#BB180C',
        700: '#990C02',
        800: '#770800',
        900: '#550600'
    },
    sun: {
        50: '#FFF9E5',
        100: '#FFEEB7',
        200: '#FFE38A',
        300: '#FFD85C',
        400: '#FFCE2E',
        500: '#FFC300',
        600: '#D6A400',
        700: '#AD8500',
        800: '#856500',
        900: '#5C4600'
    },
    grass: {
        50: '#F3FFEC',
        100: '#D9FFC3',
        200: '#BFFF9A',
        300: '#A5FF71',
        400: '#8BFF48',
        500: '#76E933',
        600: '#5DC71F',
        700: '#47A50F',
        800: '#338304',
        900: '#246100'
    },
    ocean: {
        50: '#E9F2FF',
        100: '#B7D4FF',
        200: '#86B6FF',
        300: '#5499FF',
        400: '#237BFF',
        500: '#1263DD',
        600: '#044DBB',
        700: '#003D99',
        800: '#003077',
        900: '#002255'
    },
    sky: {
        50: '#ECFDFF',
        100: '#C3FAFF',
        200: '#9AF7FF',
        300: '#70F4FF',
        400: '#45EAF8',
        500: '#2DC9D6',
        600: '#1AA8B4',
        700: '#0B8892',
        800: '#016770',
        900: '#00484E'
    }
};

declare module '@mui/material/styles' {
    interface Palette {
        greyscale: PaletteColorOptions;
        fire: PaletteColorOptions;
        sun: PaletteColorOptions;
        ocean: PaletteColorOptions;
        grass: PaletteColorOptions;
        sky: PaletteColorOptions;
        neutral: PaletteColorOptions;
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        greyscale?: PaletteOptions['primary'];
        fire?: PaletteOptions['primary'];
        sun?: PaletteOptions['primary'];
        ocean?: PaletteOptions['primary'];
        grass?: PaletteOptions['primary'];
        sky?: PaletteOptions['primary'];
        neutral: PaletteOptions['primary'];
    }

    interface PaletteColorOptions {
        white?: string;
        black?: string;
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
        800?: string;
        900?: string;
    }
}
