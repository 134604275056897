import { combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from 'authentication/redux/reducers';
import coreReducer from 'core/redux/reducers';
import dashboardReducer from 'dashboard/redux/reducers';
import { baseApi } from './api/baseApi';
import { elasticSearchApi } from './api/elasticSearchApi';

const rootReducer = combineReducers({
    api: baseApi.reducer,
    elasticSearchApi: elasticSearchApi.reducer,
    authentication: authenticationReducer,
    dashboard: dashboardReducer,
    core: coreReducer
});

declare global {
    type RootState = ReturnType<typeof rootReducer>;
}

export default rootReducer;
