import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EmployeeSliceState = EmployeeQueryParams;

const employeeManagementSlice = createSlice({
    name: 'employeeManagement',
    initialState: {
        page: 1
    } as EmployeeSliceState,
    reducers: {
        changePage: (state: EmployeeSliceState, { payload }: PayloadAction<number>) => {
            return {
                ...state,
                page: payload
            };
        }
    }
});

export const { changePage } = employeeManagementSlice.actions;

export default employeeManagementSlice.reducer;
