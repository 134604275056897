import {
    DefinitionOptions,
    attribute,
    childAttribute,
    childInteger,
    integerAttribute,
    deepChildText
} from 'stanza/jxt';
import { NS_DATAFORM, NS_FORWARD_0 } from 'stanza/Namespaces';
import { DataForm, Paging, Forward } from 'stanza/protocol';

declare module 'stanza' {
    export interface Message {
        inbox?: InboxQueryResult;
    }

    export interface IQPayload {
        inbox?: InboxQuery | InboxFin;
    }
}

export interface InboxQuery {
    type?: 'query';
    version?: string;
    node?: string;
    form?: DataForm;
    queryId?: string;
    paging?: Paging;
}

export interface InboxAll {
    active: number;
    count: number;
    unread: number;
}

export interface InboxFin {
    type: 'result';
    version?: string;
    roomInbox?: InboxResult[];
    latestMessages?: LatestMessage[];
    inbox?: InboxAll;
    paging?: Paging;
    count?: number;
}

export interface InboxResult {
    unread: number;
    from: string;
}

export interface LatestMessage {
    from: string;
    to: string;
    body: string;
    delay?: {
        timestamp: Date;
    };
    id: string;
    sent: Date;
    originId?: string;
    messageId?: string;
    partnerId?: string;
    rawMessage?: any;
}

export interface InboxQueryResult {
    version?: string;
    queryId: string;
    id: string;
    unread: number;
    item: Forward & { from?: string; to?: string };
}

const Protocol: DefinitionOptions[] = [
    {
        aliases: ['iq.inbox.form'],
        element: 'x',
        fields: {},
        type: 'form',
        namespace: NS_DATAFORM
    },
    {
        aliases: ['message.inbox.item'],
        element: 'forwarded',
        fields: {
            from: childAttribute(null, 'message', 'from'),
            to: childAttribute(null, 'message', 'to'),
            id: childAttribute(null, 'message', 'id'),
            originId: childAttribute(null, 'origin-id', 'id'),
            body: deepChildText([
                {
                    namespace: null,
                    element: 'message'
                },
                {
                    namespace: null,
                    element: 'body'
                }
            ])
            // delay: childAttribute(null, 'delay', 'stamp')
        },
        namespace: NS_FORWARD_0
    },
    // addAlias('erlang-solutions.com:xmpp:inbox:0', 'inbox', ['iq.inbox.fin']),
    {
        defaultType: 'query',
        defaultVersion: '2',
        element: 'inbox',
        fields: {
            node: attribute('node'),
            queryId: attribute('queryid')
        },
        namespace: 'erlang-solutions.com:xmpp:inbox:0',
        path: 'iq.inbox',
        type: 'query',
        typeField: 'type',
        version: '2',
        versionField: 'version'
    },
    {
        element: 'fin',
        fields: {
            count: childInteger(null, 'count'),
            unread: childInteger(null, 'unread-messages'),
            active: childInteger(null, 'active-conversations')
        },
        namespace: 'erlang-solutions.com:xmpp:inbox:0',
        path: 'iq.inbox',
        type: 'result',
        version: '2'
    },
    {
        element: 'result',
        defaultType: '2',
        fields: {
            id: attribute('id'),
            queryId: attribute('queryid'),
            unread: integerAttribute('unread')
        },
        namespace: 'erlang-solutions.com:xmpp:inbox:0',
        version: '2',
        path: 'message.inbox',
        type: '2',
        typeField: 'version'
    }
];

export default Protocol;
