import useCompanyUser from 'authentication/hooks/useCompanyUser';
import useFeatureFlag from 'core/hooks/useFeatureFlag';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import { createContext, ReactNode, useMemo, useContext, useState, useEffect } from 'react';
import { Control, FieldErrors, useForm, UseFormHandleSubmit } from 'react-hook-form';
import { useSubmitGigWorkLeadGenMutation } from '../redux/api/gigApi';

export type CompleteStageType = {
    basic_information: boolean;
    preview: boolean;
};

type GigWorkLeadGenContextType = {
    handleSubmit: UseFormHandleSubmit<GigWorkLeadGenInputData>;
    control: Control<GigWorkLeadGenInputData, any>;
    onSubmit: (data: GigWorkLeadGenInputData) => Promise<void>;
    errors: FieldErrors<GigWorkLeadGenInputData>;
    openLeadGenModal: boolean;
    setOpenLeadGenModal: (status: boolean) => void;
    openSuccessLeadGenModal: boolean;
    setOpenSuccessLeadGenModal: (status: boolean) => void;
    decideOpenLeadGenModal: () => void;
    isLoading: boolean;
};

const GigWorkLeadGenContext = createContext<GigWorkLeadGenContextType>(
    {} as GigWorkLeadGenContextType
);

export function GigWorkLeadGenProvider({ children }: { children: ReactNode }): JSX.Element {
    const { user, company } = useCompanyUser();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty }
    } = useForm<GigWorkLeadGenInputData>({
        defaultValues: {
            pic_name: user?.full_name,
            pic_phone_number: user?.phone_number,
            job_categories: [],
            expected_gig_job_description: '',
            expected_frequency: '',
            count_of_daily_workers: ''
        } as GigWorkLeadGenInputData
    });
    const track = useLazyAuthTracking('GIG WORK');
    const { gigs_feature_flags } = useFeatureFlag();
    const [openLeadGenModal, setOpenLeadGenModal] = useState(false);
    const [openSuccessLeadGenModal, setOpenSuccessLeadGenModal] = useState(false);
    const [submitLeadGen, { isLoading }] = useSubmitGigWorkLeadGenMutation();

    useEffect(() => {
        if (user) {
            setValue('pic_name', user.full_name);
            setValue('pic_phone_number', user.phone_number?.substring(3));
        }
    }, [user]);

    const decideOpenLeadGenModal = (): void => {
        if (gigs_feature_flags?.eligibility_status === 'in_review') {
            track.event('Open Success Lead Gen Gig Modal');
            setOpenSuccessLeadGenModal(true);
        } else {
            track.event('Open Lead Gen Gig Modal');
            setOpenLeadGenModal(true);
        }
    };

    const onSubmit = async (data: GigWorkLeadGenInputData): Promise<void> => {
        await submitLeadGen({ ...data, count_of_daily_workers: '', job_categories: [] });

        track.event('Submit Gig Work Lead Gen', {
            ...user,
            ...company
        });
    };

    const memoedValue = useMemo(
        () => ({
            errors,
            control,
            handleSubmit,
            openLeadGenModal,
            setOpenLeadGenModal,
            openSuccessLeadGenModal,
            setOpenSuccessLeadGenModal,
            onSubmit,
            isLoading,
            decideOpenLeadGenModal
        }),
        [control, errors, isDirty, openLeadGenModal, isLoading, openSuccessLeadGenModal]
    );

    return (
        <GigWorkLeadGenContext.Provider value={{ ...memoedValue }}>
            {children}
        </GigWorkLeadGenContext.Provider>
    );
}

export const useGigWorkLeadGenForm = (): GigWorkLeadGenContextType => {
    return useContext(GigWorkLeadGenContext);
};

export default GigWorkLeadGenContext;
