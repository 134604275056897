import { baseApi } from 'redux/api/baseApi';

const AUTH_BASE_URL = 'users/company/';
const COMPANY_BASE_URL = 'companies/';

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponseData, LoginInputData>({
            query: (data: LoginInputData) => ({
                url: `${AUTH_BASE_URL}login/`,
                method: 'POST',
                body: data
            })
        }),
        googleLogin: builder.mutation<LoginResponseData, GoogleLoginInputData>({
            query: (data: GoogleLoginInputData) => ({
                url: `${COMPANY_BASE_URL}register/social/google/`,
                method: 'POST',
                body: data
            })
        }),
        forgotPassword: builder.mutation<void, ForgotPasswordInputData>({
            query: (data: ForgotPasswordInputData) => ({
                url: `${AUTH_BASE_URL}forgot-password/`,
                method: 'POST',
                body: data
            })
        }),
        resetPassword: builder.mutation<void, ResetPasswordInputData>({
            query: (data: ResetPasswordInputData) => ({
                url: `${AUTH_BASE_URL}reset-password/`,
                method: 'POST',
                body: data
            })
        }),
        consentTnC: builder.mutation<LoginResponseData, void>({
            query: () => ({
                url: `${AUTH_BASE_URL}consent-tnc/`,
                method: 'POST'
            })
        }),
        loggedInUser: builder.query<User, void>({
            query: () => ({
                url: 'users/'
            })
        }),
        registerUser: builder.mutation<LoginResponseData, RegisterInputData>({
            query: (data: RegisterInputData) => ({
                url: `${COMPANY_BASE_URL}register/`,
                method: 'POST',
                body: data
            })
        }),
        addPhoneNumber: builder.mutation<LoginResponseData, PhoneNumberInputData>({
            query: (data: PhoneNumberInputData) => ({
                url: `${COMPANY_BASE_URL}register/phone-number/`,
                method: 'POST',
                body: data
            })
        }),
        getUserReferreeData: builder.query<UserReferreeData, string>({
            query: (referralCode: string) => `/companies/public/referral/users/${referralCode}/`
        }),
        getCompanyUserProfile: builder.query<CompanyUserProfile, void>({
            query: () => `${COMPANY_BASE_URL}company-user/`,
            providesTags: ['CompanyUser']
        })
    })
});

export const {
    useRegisterUserMutation,
    useLoginMutation,
    useGoogleLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useConsentTnCMutation,
    useLoggedInUserQuery,
    useAddPhoneNumberMutation,
    useGetUserReferreeDataQuery,
    useGetCompanyUserProfileQuery
} = authApi;
