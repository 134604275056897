import { Agent } from 'stanza';

import { IQ } from 'stanza/protocol';
import Protocol, { SmartMarkerFin, SmartMarkerQuery } from './protocol';

declare module 'stanza' {
    export interface Agent {
        getChatMarker(jid: string): Promise<SmartMarkerFin>;
    }

    export interface AgentEvents {
        smartMarker: SmartMarkerFin;
    }

    // 5. Stanza definitions MUST be placed in the Stanzas namespace
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace Stanzas {
        // 6. Attach our new definition to Message stanzas
        export interface Message {
            smartMarker?: SmartMarkerFin;
        }
    }
}

export default function (client: Agent): void {
    client.stanzas.define(Protocol);

    client.getChatMarker = async (jid) => {
        const queryid = client.nextId();
        const resp = await client.sendIQ<
            IQ & { smartMarker: SmartMarkerQuery },
            IQ & { smartMarker: SmartMarkerFin }
        >({
            id: queryid,
            smartMarker: {
                peer: jid
            },
            type: 'get'
        });

        return {
            ...resp.smartMarker,
            type: 'result'
        };
    };
}
