const MOENGAGE_APP_ID = process.env.NEXT_PUBLIC_MOENGAGE_APP_ID || 'NO_TOKEN';
const MOENGAGE_APP_KEY = process.env.NEXT_PUBLIC_MOENGAGE_APP_KEY || 'NO_TOKEN';

type MoEngageProps = {
    type: string;
    customer_id: string;
    device_id?: string | null;
    actions: Array<{
        action: string;
        attributes?: Record<string, unknown>;
        platform?: string;
        app_version?: string;
        user_time?: number;
        current_time?: number;
        user_timezone_offset?: number;
    }>;
};

export const callMoEngageApiEvent = (phoneNumber: string, actionName: string): void => {
    const requestBody: MoEngageProps = {
        type: 'event',
        customer_id: phoneNumber,
        actions: [
            {
                action: actionName
            }
        ]
    };

    const url = `https://api-01.moengage.com/v1/event/${MOENGAGE_APP_ID}`;

    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
                'Basic ' + Buffer.from(`${MOENGAGE_APP_ID}:${MOENGAGE_APP_KEY}`).toString('base64')
        },
        body: JSON.stringify(requestBody)
    }).catch(() => {
        // Ignore error
    });
};
