import { combineReducers } from '@reduxjs/toolkit';
import jobOpeningReducer from 'dashboard/jobOpening/redux/reducers';
import applicantReducer from 'dashboard/applicant/redux/reducers';
import chatReducer from 'dashboard/chat/redux/reducers';
import profileReducer from 'dashboard/profile/redux/reducers';
import gigWorkReducer from 'dashboard/gigWork/redux/reducers';
import luminaCreditReducer from 'dashboard/luminaCredit/redux/reducers';

const dashboardReducer = combineReducers({
    applicant: applicantReducer,
    jobOpening: jobOpeningReducer,
    gigWork: gigWorkReducer,
    chat: chatReducer,
    profile: profileReducer,
    luminaCredit: luminaCreditReducer
});

export default dashboardReducer;
