import { elasticSearchApi } from 'redux/api/elasticSearchApi';

export const jobOpeningESApi = elasticSearchApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobTitleRecommendation: builder.mutation<
            JobRecommendationResultData[],
            ElasticSearchBaseInputData
        >({
            query: (data: ElasticSearchBaseInputData) => ({
                url: 'registered-job-roles/_search/',
                body: data,
                method: 'POST'
            }),
            transformResponse: (
                response: ElasticSearchBaseResponseData<JobRecommendationResultData>
            ) => {
                return response.suggest.suggestion[0].options.map(
                    (value: ElasticSearchOption<JobRecommendationResultData>) => ({
                        id: value?._id as string,
                        title: value?._source?.title as string,
                        job_role_category: value?._source?.job_role_category
                    })
                );
            }
        }),
        getAssessmentRecommendation: builder.mutation<
            AssessmentRecommendation[],
            ElasticSearchBaseInputData
        >({
            query: (data: ElasticSearchBaseInputData) => ({
                url: '/assessment-groups/_search/',
                body: data,
                method: 'POST'
            }),
            transformResponse: (
                response: ElasticSearchBaseResponseData<AssessmentRecommendation>
            ) => {
                return response.suggest.suggestion[0].options.map(
                    (value: ElasticSearchOption<AssessmentRecommendation>) => ({
                        name: value?.text,
                        id: value?._id as string,
                        description: value?._source?.description as string
                    })
                );
            }
        })
    }),
    overrideExisting: false
});

export const { useGetJobTitleRecommendationMutation, useGetAssessmentRecommendationMutation } =
    jobOpeningESApi;
